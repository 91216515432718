const Reducer = (state, action) => {
    switch (action.type) {
        case 'setAll':
            return {
                ...state,
                ...action.payload
            };
        default: return {
            ...state
        }
    }
};

export default Reducer;