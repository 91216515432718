import React from 'react'
import Typography from '@mui/material/Typography';

const MachineLearning = () => {
    return (
        <>
            <Typography variant='body1'>
                <h4>Machine Learning: Custom Development</h4>
                <p>
                    To avoid mistakes when developing custom machine learning , it is helpful to outline the steps involved in this work.
                </p>
                <p>
                    Since the duration and complexity of the development of machine learning systems, depending on the requirements, may differ by several orders of magnitude, the first step is to fix the desired result that the customer wants to get.
                </p>
                <p>
                    Having finished with the formulation of the problem and having roughly decided on the solution (and also making sure that the problem can be solved within a reasonable time frame and budgets), we proceed to the next step: collecting data.
                </p>
                <p>
                    Next, comes the most crucial stage – data markup. The labeled data is divided into several groups: the training group is transferred to the machine learning engineers, and the test data remains with the client. This insures the customer against the most common mistake of machine learning – the so-called overfitting: artificial intelligence can learn to solve a problem with the highest accuracy on a training set, but be absolutely helpless on unfamiliar data.
                </p>
                <p>
                    The learning process itself is the subject of multi-page scientific articles and a whole direction in science, therefore, omitting the details, it is worth noting that ML engineers select learning methods from simple to complex, controlling the quality of models using several selected metrics.
                </p>
                <p>
                    To minimize ML development costs as much as possible, we always first look for previously created ML solutions that can be adapted without a full training cycle. Trained models are necessarily tested on data that was not involved in the training process. This stage can be performed automatically, showing the customer the progress in development almost daily.
                </p>
                <p>
                    Since machine learning methods are very demanding on hardware resources, at the final stage, optimization of the ready-made solution for memory or processor consumption is often required. Sometimes it is enough to sacrifice a fraction of a percent of the accuracy of the algorithms to speed up the ML solution dozens of times.
                </p>
                <p>
                    Machine learning (ML) is a class of AI techniques that focuses on building systems that learn – or improve performance – through data analysis.
                </p>
                <p>
                    We can help with outsource machine learning development and provide advisory services.
                </p>
                <h5>Prediction</h5>
                <p>
                    Used to predict some values based on a labeled Dataset. For example based on interaction with the client predict his LTV, or based on some property factors predict market property value.
                </p>
                <h5>Classification</h5>
                <p>
                    Used to classify something based on a labeled Dataset. For example based on the first few actions predict segment for user, or based on music file predict style of music.
                </p>
                <h5>Clusterization</h5>
                <p>
                    Used to find clusters in raw data. For example using user data/user behaviour, find user segments, or based on text description/photo find product category.
                </p>
                <h5>Forecasting</h5>
                <p>
                    Used to forecast values in the feature based on some Time-Series, finding trends, seasonality, correlations. For example forecast demand for hot-dogs in the next few months, or forecast seasonal trends.
                </p>
                <h5>Anomaly detection</h5>
                <p>
                    Used to find events which are unlikely and are knocked out of the permissible limits. Usually used for monitoring of markets, technical systems, and security events. Also used to clear data in datasets. For example finding all images in a dataset with a category that were marked incorrectly, or finding that price on some product increased too much.
                </p>
                <h5>Data Analyzing</h5>
                <p>
                    Going deep into the data to find out some correlations which may lead to some insights. Usually used as a first step before model training, but also can be used alone. For example finding that sales decrease LTV of users and hurt business, or calculate LTV for different types of marketing campaigns.
                </p>
                <h5>Recommendation systems</h5>
                <p>
                    Used to personalize offering for clients to make him find the right items faster. For example google search results, or finding movies/songs that users will like, or recommend products.
                </p>
                <hr />
                <h5>Image classification capabilities</h5>
                <p>
                    Beyond retail, financial services, and online sales, machine learning can be used in a wide variety of scenarios. It is used very effectively in the scientific, energy and construction industries, as well as in healthcare. Deep learning techniques such as neural networks are often used to classify images. They are good at identifying the most important characteristics of the image, even in the presence of secondary factors. For example, neural networks distinguish between perspective, lighting level, scale or interference and can adjust image characteristics to provide the best possible result.
                </p>
                <h5>Modeling the value of the customer service life cycle</h5>
                <p>
                    Modeling the value of the customer service lifecycle is very important not only for online sales companies, but also for businesses in other industries.  Customer service cycle value models are especially effective at predicting the future profit that an individual customer will generate over a given period in the future. This insight helps you focus your marketing efforts on the customers who bring you the most value, encouraging them to engage with the brand more often. Customer service lifecycle value models also improve targeting efficiency and thus attract new paying customers.
                </p>
                <h5>Customer churn prediction model</h5>
                <p>
                    Attracting new customers requires more financial and time costs than maintaining the level of satisfaction and loyalty of existing ones. Simulation helps identify customers who might leave and why they leave. An effective model uses machine learning algorithms to assess and rank everything from risk metrics for individual customers to causes of churn. The results obtained play an important role in the development of a retention strategy.
                </p>
            </Typography>
        </>
    )
}

export default MachineLearning