import React from 'react';
import { Paper, Typography } from '@mui/material';
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Box } from '@mui/system';

const ContactUs = () => {
  // const { enqueueSnackbar } = useSnackbar();

  return (
    <MDBContainer fluid>
      <MDBRow className='m-0 pb-4'>
        <MDBCol size='12' className='px-0 px-md-2 text-center'>
          <Typography component='div' className='text-center py-5  text-gradient d-inline-block' variant='h4'>Contact Splash Software</Typography>
          <Paper
            className='py-4 px-3'
            sx={{
              color: '#aaa !important',
              backgroundColor: '#050505',
              boxShadow: '0 0 10px #fff6',
              '& h5': {
                color: '#ddd !important',
                paddingTop: 3
              },
            }}>
            <MDBRow>
              <MDBCol size='12' className='text-center'>
              </MDBCol>
              <MDBCol size='12' md='6' className='text-center'>
                <iframe title='Splash Software Dubai, Location on Map' className='rounded-5' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3610.429060334552!2d55.26438841500934!3d25.18874918390021!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f69178c4235fd%3A0xec660d5352ae96a7!2sSplash%20Software!5e0!3m2!1sen!2sae!4v1653068419699!5m2!1sen!2sae"
                  style={{ 'border': 0, 'width': '100%', height: '25rem' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
              </MDBCol>
              <MDBCol size='12' md='6' className='text-center'>
                <MDBRow>
                  <MDBCol size="12" className="text-md-start pb-2">
                    <img alt='Splash Software Logo' style={{ height: '3rem' }} src='/logo/splashsoftware-gradient.png'></img>
                  </MDBCol>
                  <Typography component='div' variant="body1" className=" text-md-start text-white">
                    <MDBCol size="12" className=''>
                      <MDBIcon icon='mobile-alt' className="p-1 pe-2"></MDBIcon>
                      +971 (04) 456 1660
                    </MDBCol>
                    <MDBCol size="12" className=''>
                      <MDBIcon icon='envelope' className="p-1"></MDBIcon>
                      inquiry@spls.ae
                    </MDBCol>
                    <MDBCol size="12" className="-d-flex -align-items-center">
                      <MDBIcon icon='map-marker-alt' className="p-1"></MDBIcon>
                      <a rel="noreferrer" href='https://goo.gl/maps/k6PJZ4NnaK4aeq696' target='_blank' className='text-white'>
                        No A502, Opus Tower,
                        Dubai - UAE
                      </a>
                    </MDBCol>
                  </Typography>
                </MDBRow>
                <MDBRow className='my-1'>
                  <MDBCol size='12' className='text-start'>
                    {/* <ThemeButton sx={{
                      fontSize: '0.8rem',
                      background: '#0af',
                      color:'white'
                    }}>
                      <Typography variant='body2'>Call Us</Typography>
                      <Typography variant='subtitle1' className='font-weight-bold'>+971 (04) 456 1660</Typography>
                    </ThemeButton> */}
                    <a rel="noreferrer" className='mx-1' href='https://instagram.com/spls.ae' target='_blank'>
                      <Box sx={{
                        display: 'inline-block',
                        width: '2rem',
                        height: '2rem',
                        textAlign: 'center',
                        borderRadius: '0.5rem',
                        color: '#fff',
                        fontSize: '1.8rem',
                        lineHeight: '2.2rem',
                        verticalAlign: 'middle',
                        // background: '#d6249f',
                        background: 'radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%,#d6249f 60%,#285AEB 90%)',
                        boxShadow: '0px 3px 10px rgba(0,0,0,.25)'
                      }}
                        className='d-inline-block'>
                        <MDBIcon fab icon='instagram'></MDBIcon>
                      </Box>
                    </a>

                    <a rel="noreferrer" className='mx-1' href='tel:+971000000000' target='_blank'>
                      <Box sx={{
                        display: 'inline-block',
                        width: '2rem',
                        height: '2rem',
                        textAlign: 'center',
                        borderRadius: '0.5rem',
                        color: '#fff',
                        fontSize: '1.8rem',
                        lineHeight: '2.2rem',
                        verticalAlign: 'middle',
                        background: '#25D366',
                        boxShadow: '0px 3px 10px rgba(0,0,0,.25)'
                      }}
                        className='d-inline-block'>
                        <MDBIcon fab icon='whatsapp'></MDBIcon>
                      </Box>
                    </a>
                    {/* <a rel="noreferrer" className='mx-1' href='https://wa.me/971000000000' target='_blank'>
                      <Box sx={{
                        display: 'inline-block',
                        width: '2rem',
                        height: '2rem',
                        textAlign: 'center',
                        borderRadius: '0.5rem',
                        color: '#fff',
                        fontSize: '1.8rem',
                        lineHeight: '2.2rem',
                        verticalAlign: 'middle',
                        background: '#25D366',
                        boxShadow: '0px 3px 10px rgba(0,0,0,.25)'
                      }}
                        className='d-inline-block'>
                        <MDBIcon fab icon='whatsapp'></MDBIcon>
                      </Box>
                    </a> */}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </Paper>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default ContactUs