import './styles/App.scss';
import React, { useEffect, useContext, useRef } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Layout from "./layout/Layout";
import { Context } from './store'
import Lobby from './Lobby';
import { setActive } from './helpers/publicHelper';
import Page from './pages/Page';
import AboutUs from './pages/splash/AboutUs';
import ContactUs from './pages/splash/ContactUs';
import Hiring from './pages/splash/Hiring';
import ServiceList from './pages/splash/ServiceList';
import QR from './pages/splash/QR';
import WrongWayRacer from './pages/assessment/WrongWayRacer';

function App(props) {

  return (
    <>
      <Router>
        <Switch>
          <Route path='/' exact>
            <Layout>
              <Switch>
                <Route path='/' exact component={Lobby} />
              </Switch>
            </Layout>
          </Route>
          <Route>
            <Layout>
              <Switch>
                <Route path='/services/:category?/:service?' exact component={Page} />
                <Route path='/servicelist/:category?/' exact component={ServiceList} />
                <Route path='/splash/about us' exact component={AboutUs} />
                <Route path='/splash/contact us' exact component={ContactUs} />
                <Route path='/splash/hiring' exact component={Hiring} />
                <Route path='/assessment/wrongwayracer' exact component={WrongWayRacer} />
                <Route path='/qr/:id' exact component={QR} />
              </Switch>
            </Layout>
          </Route>

        </Switch>
      </Router>
    </>
  );
}
export default App;



