import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { MDBIcon } from 'mdb-react-ui-kit';
import { isMobile } from 'react-device-detect';

const timelineData = [
    {
        title1: 'Defint Your Token Properties',
        title2: 'Step 1',
        align: 'right',
        text: 'First of all, you need to decide what your cryptotoken will do. If it’s a typical ERC-20 token meant to attract investors, it will have the inherent properties of the ERC-20 standard. ',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Develop a Smart Contract',
        title2: 'Step 2',
        align: 'left',
        text: 'Any cryptotoken is governed by a smart contract, which is a piece of software running on a blockchain. So to make your own token, you need to code a smart contract.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Run QA on a TestChain',
        title2: 'Step 3',
        align: 'left',
        text: 'When creating a vanilla smart contract for a crypto token, keep in mind that it will be quite a hassle to replace it in case there’s a bug. Therefore run multiple tests on a test blockchain like Rinkeby or Ropsten.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Deploy to Blockchain',
        title2: 'Step 4',
        align: 'left',
        text: 'In any case, ensure that the contract functions perform flawlessly on a test net (user can send and receive cryptotokens, the contract executes the rest of its features, etc.) before deploying it to the mainnet (Ethereum).',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
]

const TokenDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Be it centralized or decentralized, Our experienced developers offer all-inclusive crypto exchange development services in Dubai with core functionalities that will enable seamless, secure, instant, and efficient transactions.
                </p>
                <p>
                    Our white-label Bitcoin exchange platform possesses an advanced user interface and has a robust code to prevent any chances of hacks. Our Dubai based white label Bitcoin exchange software development solutions comply with all the relevant legal norms. We ensure high performance by integrating the best technological and security features. It is highly scalable and will work smoothly with different devices and operating systems. The software architecture is established flexibly enabling full customization.
                </p>
            </Typography>
            <Timeline position={isMobile ? '' : 'alternate'}>
                {
                    timelineData.map((timelineItem, i) => <StyledTimelineItem {...timelineItem} key={i}></StyledTimelineItem>)
                }

            </Timeline>
            <Typography variant='body1' className='py-4 text-center'>
                <p>
                    At Splash Software We are offering the industry leading token development development services.
                </p>
            </Typography>
        </>
    )
}

const StyledTimelineItem = (props) => {
    const { align, icon, title1, title2, text, color } = props;
    return <TimelineItem sx={{
        "&:before": {
            display: isMobile ? "none" : ''
        }
    }}>
        {!isMobile &&
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align={align}
                variant="body2"
                color="text.secondary">
                {title2}
            </TimelineOppositeContent>
        }
        <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: color }} />
            <TimelineDot color={color}>
                {icon}
            </TimelineDot>
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                {title1}
            </Typography>
            <Typography variant='body2'>
                {text}
            </Typography>
        </TimelineContent>
    </TimelineItem>
}

export default TokenDevelopment