import React from 'react'
import Typography from '@mui/material/Typography';

const MobileDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Being a team of Mobile experts, Splash Software can deliver every aspect of a Mobile project, e.g. analysis, design, development, testing, and more
                </p>
                <h5>Mobile App Development</h5>
                <p>
                    We provide expert-level Mobile development services to turn your vision into a high-quality Mobile-based solution, exactly the way you envisioned 
                </p>
                <h5>Cloud App</h5>
                <p>
                With us, you can implement even the most challenging cloud app development projects to succeed in a data-driven world 
                </p>
                <h5>Mobile Optimization</h5>
                <p>
                    Our experts will perform an audit of your Mobile-based solutions and apps, with a range of A/B tests, generating a plan of improvements to undertake 
                </p>
                <h5>Mobile Design</h5>
                <p>
                If you need a Mobile design company to deliver visually engaging IT projects with outstanding UI/UX, Splash Software and our user-centered approach is your choice 
                </p>
                <h5>Mobile Quality Assurance</h5>
                <p>
                Take advantage of a full range of QA services to ensure the stable operation of your Mobile apps and presences and detect bugs before going to production 
                </p>
                <h5>Mobile Integration</h5>
                <p>
                If your Mobile project is data-intensive and needs to be integrated with third-party Mobile presences or apps, Splash Software will assist you with such integrations 
                </p>

            </Typography>
        </>
    )
}

export default MobileDevelopment