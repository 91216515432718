const blockchainServices = [
    {
        title: 'NFT Token Development',
        url: '/services/blockchain/nft development'
    },

    {
        title: 'Coin and Token Development',
        url: '/services/blockchain/coin and token development'
    },
    {
        title: 'Smart Contracts Development',
        url: '/services/blockchain/smart contract development'
    },
    {
        title: 'Crypto Exchange Development',
        url: '/services/bockchain/crypto exchange development'
    },
    // {
    //     title: 'Metaverse',
    //     url: '/services/Blockchain/Metaverse'
    // },
]

const splashSoftware = [
    {
        title: 'About Us',
        url: '/splash/about us'
    },
    {
        title: 'Contact Us',
        url: '/splash/contact us'
    },
    {
        title: 'We Are Hiring',
        url: '/splash/hiring'
    },
    // {
    //     title: 'Social Media',
    //     url: '/services/SplashSoftware/Social Media'
    // }
]

const cloudServices = [
    {
        title: 'Machine Learning',
        url: '/services/cloud/machine learning'
    },
    {
        title: 'Artificial Intelligence',
        url: '/services/cloud/artificial intelligence'
    },

    // {
    //     title: 'Cognitive Search',
    //     url: '/services/Data/Cognitive Search'
    // },
    {
        title: 'Software as a Service',
        url: '/services/cloud/software as a service'
    },
]

const dataServices = [
    {
        title: 'Cloud Storage',
        url: '/services/data/cloud storage'
    },
    {
        title: 'Big Data Analysis',
        url: '/services/data/data analysis'
    },

    {
        title: 'Big Data Solutions',
        url: '/services/data/big data'
    },
]

// const gamingSolutions = [
//     {
//         title: '3D Modeling',
//         url: '/services/Gaming/3D Modeling'
//     },
//     {
//         title: 'Game Development',
//         url: '/services/Gaming/Game Development'
//     },
//     {
//         title: 'Cloud GPU Rendering',
//         url: '/services/Gaming/Cloud GPU Rendering'
//     },
//     {
//         title: 'Online Gaming Services',
//         url: '/services/Gaming/Online Gaming Services'
//     }
// ]

const webDevelopment = [
    {
        title: 'Website Development',
        url: '/services/web/website development'
    },
    {
        title: 'Website UI/UX Design',
        url: '/services/web/website ui ux design'
    }

]

const mobileDevelopment = [
    {
        title: 'App Development',
        url: '/services/mobile/app development'
    },
    {
        title: 'Mobile UI/UX Design',
        url: '/services/mobile/mobile ui ux design'
    },
    // {
    //     title: 'PWA Development',
    //     url: '/services/Mobile/PWA Development'
    // },
    // {
    //     title: 'Publish on Stores',
    //     url: '/services/Mobile/Publish on Stores'
    // }
]

const softwareDevelopment = [
    {
        title: 'Windows Application',
        url: '/services/software/windows application'
    },
    {
        title: 'macOS Application',
        url: '/services/software/macos application'
    },
    // {
    //     title: 'PWA Development',
    //     url: '/services/Software/PWA Development'
    // },
    // {
    //     title: 'Publish on Stores',
    //     url: '/services/Software/Publish on Stores'
    // }
]

export {
    blockchainServices,
    dataServices,
    cloudServices,
    webDevelopment,
    mobileDevelopment,
    softwareDevelopment,
    // gamingSolutions,
    splashSoftware
}