import React from 'react';
import { Button } from '@mui/material';
import { MDBIcon } from 'mdb-react-ui-kit';
import theme from '../DefaultTheme'

// const styles = {
//     disabled: {
//         opacity: 0.4
//     }
// };

const generateStyledButton = (props) => {
    let { color, variant, size, disabled } = props;
    color = theme.palette[color] ? theme.palette[color] : theme.palette.secondary;
    const outlined = variant == 'outlined';
    const sx = {
        transition: 'all 0.1s ease-in-out',
        fontSize: !size ? '0.7rem !important' : size == 'xs' ? '0.6rem !important' : size == 'small' ? '0.5rem !important' : size == 'medium' ? '0.8rem !important' : '1rem !important',
        // lineHeight: !size ? '0.7rem !important' : size == 'xs' ? '0.6rem !important' : size == 'small' ? '0.5rem !important' : size == 'medium' ? '0.8rem !important' : '1rem !important',
        background: (outlined ? 'transparent' : color.main) + ' !important',
        color: (outlined ? color.main : theme.palette.primary.main) + ' !important',
        borderWidth: `1px`,
        borderStyle: 'solid',
        borderColor: color.main,
        boxShadow: `0px 0px 10px ${color.main}`,
        '&:hover': {
            textShadow: outlined ? `0px 0px 5px ${color.main}` : '#000',
            boxShadow: `0px 0px 5px ${color.main}`,
        },
        opacity: disabled ? 0.4 : 1
    }
    return sx
}


function ThemeButton(props, ref) {
    const { classes, children, className, variant, waiting, disabled, color, size, ...other } = props;
    return (
        <Button
            className={'rounded-2 text-center position-relative ' + className}
            sx={generateStyledButton(props)}
            disabled={disabled}
            // ref={ref}
            {...other}>
            {waiting && <MDBIcon className='position-absolute m-auto' style={{
            }} spin icon='spinner' ></MDBIcon>}
            <span className={`${waiting ? 'invisible' : ''}`}>{children || 'Button'}</span>
        </Button >
    );
}

// ThemeButton.propTypes = {
//     children: PropTypes.node,
//     classes: PropTypes.object.isRequired,
//     className: PropTypes.string,
// };

export default ThemeButton
