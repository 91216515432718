import { Accordion, AccordionDetails, AccordionSummary, Chip, CircularProgress, Divider, FormControl, InputLabel, List, ListItem, MenuItem, Modal, Select, TextField, Typography, Fade } from '@mui/material';
import { Box } from '@mui/system';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React, { useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Context } from '../../store';
import FileUpload from '../../../src/components/FileUpload';
import axios from 'axios';
import { useSnackbar } from 'notistack';
import ThemeButton from '../../layout/components/ThemeButton';
import { useLocation } from 'react-router-dom';
import validator from 'validator';
import { vacancies } from './JD';

const HiringForm = (props) => {
    const { activePosition } = props;
    const [state, dispatch] = useContext(Context);
    const { enqueueSnackbar } = useSnackbar();

    const [modalOpen, setModalOpen] = useState(false)
    const [values, setValues] = useState({
        position: activePosition,
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        cv: '',
        file: false,
        skills: {},
        portfolio: { '0': '', '1': '', '2': '', '3': '', '4': '' },
        cvUploading: false,
    })


    useEffect(() => {
        setValues({
            ...values,
            position: values.position || 'Front-End Developer',
            skills: {}
        })
    }, [values.position])



    const submitForm = () => {
        if (!values.firstName) {
            console.log('!values.firstName')
            return enqueueSnackbar('Please fill the First Name field.', {
                variant: 'error', autoHideDuration: 3000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #f06 !important',
                        border: '1px solid #f06',
                    },
                    color: '#fff'
                }
            })
        }
        if (!values.lastName) {
            console.log('!values.lastName')
            return enqueueSnackbar('Please fill the Last Name field.', {
                variant: 'error', autoHideDuration: 3000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #f06 !important',
                        border: '1px solid #f06',
                    },
                    color: '#fff'
                }
            })
        }
        if (!validator.isEmail(values.email)) {
            console.log('!values.email')
            return enqueueSnackbar('Please enter a valid email address.', {
                variant: 'error', autoHideDuration: 3000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #f06 !important',
                        border: '1px solid #f06',
                    },
                    color: '#fff'
                }
            })
        }
        if (!(validator.isMobilePhone(values.phone) && validator.isLength(values.phone, { min: 10, max: 13 }))) {
            console.log('!values.phone')
            return enqueueSnackbar('Please enter a valid phone number', {
                variant: 'error', autoHideDuration: 3000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #f06 !important',
                        border: '1px solid #f06',
                    },
                    color: '#fff'
                }
            })
        }
        if (!values.cv) {
            console.log('!values.cv')

            return enqueueSnackbar('Please upload your CV.', {
                variant: 'error', autoHideDuration: 3000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #f06 !important',
                        border: '1px solid #f06',
                    },
                    color: '#fff'
                }
            })
        }

        if (window.grecaptcha) {
            window.grecaptcha.ready(() => {
                window.grecaptcha
                    .execute(state.recaptchaSiteKey, { action: "homepage" })
                    .then(captcha => {
                        const headers = { captcha };
                        axios.post(process.env.REACT_APP_API_HOST + '/hiring/apply', values, { headers })
                            .then((res) => {
                                res = res.data;
                                if (res.success) {
                                    setValues({
                                        ...values,
                                        firstName: '',
                                        lastName: '',
                                        email: '',
                                        phone: '',
                                        cv: '',
                                        skills: {}
                                    });
                                    enqueueSnackbar(res.result, { variant: 'success', autoHideDuration: 3000 })
                                    setModalOpen(true)
                                } else {

                                }
                            })
                            .catch(err => {

                            })
                    })
            })
        } else {
            console.log('Captcha failed')
            enqueueSnackbar('Recaptcha failed. Refresh the page.', { variant: 'error', autoHideDuration: 3000 })
        }
    }

    const uploadSuccess = (file) => {
        setValues({ ...values, cvUploading: false })

        console.log(file)
        if (file.length == 1) {
            setValues({ ...values, cv: file[0].url })
            enqueueSnackbar(<span className='text-success'><MDBIcon icon='check' className='text-success pe-2'></MDBIcon> Uploaded.</span>, {
                // variant: 'success',
                autoHideDuration: 2000,
                className: 'bg-black',
                sx: {
                    '& > div': {
                        boxShadow: '0px 0px 10px #00b74a !important',
                        border: '1px solid #00b74a',
                    },
                    color: '#fff'
                }
            })
        }
    }

    const uploadError = (message) => {
        setValues({ ...values, cvUploading: false })
        return enqueueSnackbar(message, {
            variant: 'error', autoHideDuration: 3000,
            className: 'bg-black',
            sx: {
                '& > div': {
                    boxShadow: '0px 0px 10px #f06 !important',
                    border: '1px solid #f06',
                },
                color: '#fff'
            }
        })
    }

    const onUploading = () => {
        setValues({ ...values, cvUploading: true })

        enqueueSnackbar('Uploading ...', {
            variant: 'info',
            autoHideDuration: 1000,
            className: 'bg-black',
            sx: {
                '& > div': {
                    boxShadow: '0px 0px 10px #00b74a !important',
                    border: '1px solid #00b74a',
                },
                color: '#fff'
            }
        })
    }

    return <MDBCol size='12' className='m-auto'>
        <Box className='p-3 bg-black rounded-6 mb-4' sx={{
            background: '#000a',
        }} >

            <form id='HiringApplicationForm' autoComplete="off" noValidate='noValidate' className='text-center pb-3' onSubmit={e => { e.preventDefault(); }}>
                <MDBRow className='justify-content-center'>
                    <MDBCol size='12' className='mb-3 text-start'>
                        <FormControl fullWidth>
                            <InputLabel className='text-white'>Position <span className='text-pink'>*</span></InputLabel>
                            <Select
                                MenuProps={{
                                    TransitionComponent: Fade
                                }}
                                value={values.position}
                                label="Position"
                                onChange={e => setValues({ ...values, position: e.target.value })}
                            >
                                {
                                    vacancies.map((item, i) =>
                                        <MenuItem key={i} value={item.jobTitle}>{item.jobTitle}</MenuItem>
                                    )
                                }
                            </Select>

                        </FormControl>
                    </MDBCol>
                    <MDBCol size='12' md='6' className='mb-3'>
                        <FormControl variant="standard" className='w-100'>
                            <TextField
                                InputLabelProps={{
                                    className: 'text-white'
                                }}
                                inputProps={{
                                    className: ' border-white'
                                }}
                                label={<span><span className='opacity-40'>First Name</span> <span className='text-pink'>*</span></span>}
                                value={values.firstName}
                                onChange={e => setValues({ ...values, firstName: e.target.value })} />
                        </FormControl>
                    </MDBCol>
                    <MDBCol size='12' md='6' className='mb-3'>
                        <FormControl variant="standard" className='w-100'>
                            <TextField
                                InputLabelProps={{
                                    className: 'text-white'
                                }}
                                inputProps={{
                                    className: ' border-white'
                                }}
                                label={<span><span className='opacity-40'>Last Name</span> <span className='text-pink'>*</span></span>}
                                value={values.lastName}
                                onChange={e => setValues({ ...values, lastName: e.target.value })} />
                        </FormControl>
                    </MDBCol>
                    <MDBCol size='12' md='6' className='mb-3'>
                        <FormControl variant="standard" className='w-100'>
                            <TextField
                                InputLabelProps={{
                                    className: 'text-white'
                                }}
                                inputProps={{
                                    className: ' border-white'
                                }}
                                label={<span><span className='opacity-40'>Email</span> <span className='text-pink'>*</span></span>}
                                value={values.email}
                                onChange={e => setValues({ ...values, email: e.target.value })} />
                        </FormControl>
                    </MDBCol>
                    <MDBCol size='12' md='6' className='mb-3 noArrow'>
                        <FormControl variant="standard" className='w-100'>
                            <TextField
                                InputLabelProps={{
                                    className: 'text-white'
                                }}
                                inputProps={{
                                    className: ' border-white',
                                    maxLength: 13,
                                }}
                                label={<span><span className='opacity-40'>Phone</span> <span className='text-pink'>*</span></span>}
                                value={values.phone}
                                onChange={e => setValues({ ...values, phone: e.target.value.replace(/[^0-9]/g, '') })} />
                        </FormControl>
                    </MDBCol>
                    <MDBCol size='12' className='mb-3'>
                        <MDBCol size='12'>
                            <hr />
                        </MDBCol>
                        <MDBCol size='12'>
                            <Typography variant='h6' className='text-gradient font-weight-bold d-inline-block mb-4'>Skill Level</Typography>
                        </MDBCol>
                        <MDBRow>
                            {
                                vacancies.filter(v => v.jobTitle == values.position)[0] && vacancies.filter(v => v.jobTitle == values.position)[0].skills.map((skill, i) =>
                                    <SkillMeter values={values} setValues={setValues} key={i} title={skill} />
                                )
                            }
                            <MDBCol size='12'>
                                <hr className='mb-0 pb-0' />
                            </MDBCol>
                        </MDBRow>
                        {(['Full-Stack Developer', 'Front-End Developer', 'Back-End Developer', 'UI/UX Designer'].indexOf(values.position) >= 0) && <MDBRow className='mt-3'>
                            <MDBCol size='12'>
                                <Typography variant='h6' className='text-gradient font-weight-bold d-inline-block mb-4'>Link To Your Portfolio</Typography>
                            </MDBCol>
                            {
                                ['0', '1', '2', '3', '4', '5'].map((index, i) =>
                                    <PortfolioInput values={values} setValues={setValues} key={i} index={index} />
                                )
                            }
                            <MDBCol size='12'>
                                <hr className='mb-0 pb-0' />
                            </MDBCol>
                        </MDBRow>}
                    </MDBCol>
                    <MDBCol size='12' md='6' className='text-center text-md-start d-flex justify-content-start align-items-center'>
                        <div className='d-inline-block position-relative overflow-hidden text-start' style={{
                            width: '10rem'
                        }}>

                            {/* <MDBIcon icon='upload' className='text-info' style={{ fontSize: '1rem' }}></MDBIcon> */}
                            {
                                values.cv ?
                                    <Typography variant='subtitle1'>
                                        <a className='text-success' href={values.cv} target='_blank'>Uploaded successfully.</a>
                                        <span onClick={() => setValues({ ...values, cv: '' })} className='text-pink cursor-pointer rounded-2 border border-pink px-2 py-0 d-flex align-items-center w-max'>
                                            <MDBIcon icon='times' className='pe-1'></MDBIcon>
                                            Remove
                                        </span>
                                    </Typography>
                                    :
                                    <>
                                        {
                                            values.cvUploading ?
                                                <div className='text-center'>
                                                    <CircularProgress className='m-auto' color='pink' disableShrink />
                                                </div>
                                                :
                                                <>
                                                    <ThemeButton color='pink' className=' py-2 rounded-0 box-shadow-none px-2 px-md-3'>
                                                        <div className='d-flex justify-content-center align-items-center' style={{
                                                        }}>
                                                            <MDBIcon icon='upload' className='pe-2 text-white'></MDBIcon>
                                                            <span className=' text-white text-start'>
                                                                Upload Your CV
                                                            </span>
                                                        </div>
                                                    </ThemeButton>
                                                    <FileUpload
                                                        style={{ top: 0, left: 0 }}
                                                        className='opacity-0 cursor-pointer position-absolute h-100 w-100 py-5 small'
                                                        name={''}
                                                        onUploading={onUploading}
                                                        onUploaded={(file) => uploadSuccess(file)}
                                                        onError={uploadError}>
                                                    </FileUpload>
                                                </>
                                        }


                                    </>
                            }
                        </div>

                        <Typography variant='subtitle1' className='ms-2 ms-md-3'>
                            {values.cv ? <></> : <span className='text-white opacity-40 d-inline-block' style={{ lineHeight: '1rem' }}>
                                <span className='d-block'>
                                    Valid file types:
                                </span>
                                <span>
                                    jpg, jpeg, png, pdf
                                </span>
                            </span>}
                        </Typography>
                    </MDBCol>
                    <MDBCol size='12' md='6' className='text-md-end pt-4 pt-md-0 d-flex align-items-center justify-content-center justify-content-md-end'>
                        <ThemeButton onClick={submitForm} color='success' className='rounded-0 box-shadow-none py-2 px-5'>Submit</ThemeButton>
                    </MDBCol>
                </MDBRow>
            </form>
        </Box>
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            onBackdropClick={() => setModalOpen(false)}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: ['85vw', '400px'],
                    bgcolor: '#000',
                    border: '2px solid #000',
                    boxShadow: '0 0 10px #0f0',
                    height: 'fit-content'
                }}
                className='text-center text-success rounded-6  px-3 py-4'>
                <MDBIcon icon='check-circle' size='2x'></MDBIcon>
                <Typography className='pt-3' variant="h6" component="h2">
                    Your application has been submitted and is under review.
                </Typography>
                <Typography className='text-white opacity-80' variant='body1' sx={{ mt: 2 }}>
                    We have received your application for the <strong>{values.position}</strong> job at Splash Software.
                </Typography>
                <Typography className='text-white opacity-80' variant='body1' sx={{ mt: 2 }}>
                    You will be notified within 5 business days regarding the status of your application. If there are any issues with your application's approval, we will reach out to you.
                </Typography>
            </Box>
        </Modal>
    </MDBCol>
}

const SkillMeter = (props) => {
    const { title, values, setValues } = props;
    const rateName = ['No Experience', 'Novice', 'Beginned', 'Competent', 'Proficient', 'Expert'];

    return <MDBCol size='12' lg='6'>
        <MDBRow className='m-0'>
            <MDBCol size='12' md='2' lg='4' className='text-start pb-1 px-0'>
                <Typography variant='subtitle1'>
                    {title}
                </Typography>
            </MDBCol>
            <MDBCol size='12' md='10' lg='8' className='text-start small px-0'>
                <MDBIcon onClick={() => setValues({ ...values, skills: { ...values.skills, [title]: 0 } })} className={'text-shadow-danger opacity-50 cursor-pointer'} icon={'star'}></MDBIcon>
                <span className='text-gradient'>
                    {[...Array(5)].map((x, i) => <MDBIcon key={i} onClick={() => setValues({ ...values, skills: { ...values.skills, [title]: (i + 1) } })} className={'cursor-pointer d-inline ' + (i < (values.skills[title]) ? ' ' : ' font-weight-lighter')} icon={'star'}></MDBIcon>)}
                </span>
                <Typography variant='subtitle2' className='d-inline-block ms-1 opacity-50'>
                    {rateName[values.skills[title] || 0]}
                </Typography>
            </MDBCol>
        </MDBRow>
    </MDBCol>
}

const PortfolioInput = (props) => {
    const { index, values, setValues } = props;
    return <MDBCol size='12' md='6' className='mb-3 noArrow'>
        <FormControl variant="standard" className='w-100'>
            <TextField
                InputLabelProps={{
                    className: 'text-white'
                }}
                inputProps={{
                    className: ' border-white',
                    maxLength: 200,
                }}
                label={<span className='opacity-30'>Link To Portfolio {parseInt(index) + 1}</span>}
                value={values.portfolio[index]}
                onChange={e => setValues({ ...values, portfolio: { ...values.portfolio, [index]: e.target.value } })} />
        </FormControl>
    </MDBCol>
}

export default HiringForm
