import React from 'react'
import { Paper, Typography } from '@mui/material'
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit'

const AboutUs = () => {
  return (
    <MDBContainer fluid>
      <MDBRow className='m-0 pb-4'>
        <MDBCol size='12' className='px-0 px-md-2'>
          <Typography className='text-center py-5 text-white' variant='h4'>About Splash Software</Typography>
          <Paper
            className='py-4 px-3'
            sx={{
              color: '#aaa !important',
              backgroundColor: '#050505',
              boxShadow: '0 0 10px #fff6',
              '& h5': {
                color: '#ddd !important',
                paddingTop: 3
              },
            }}>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>Who is Splash Software?</Typography>
            <Typography variant='body1' className='mb-2'>
              Splash Software L.L.C is Dubai based software company that registered from 2020 under license no: 924267 with activity of Computer Systems & Communication Equipment Software Design.
            </Typography>
            <Typography variant='body1' className='my-2'>
              We are a team of experts who develop blockchain systems and softwares based on your needs under UAE, GCC and International law, we provide consultant services and support the digital industry.
            </Typography>
            <Typography variant='body1' className='my-2'>
              The team modernizing your needs on highest artificial intelligence technology to create seamless experience of usage.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Today, the breadth of services we provide is quite remarkable for our size and enables us to punch well above our weight. Our expertise spans cloud migration services, IT infrastructure, application specializations, software development, native mobile app development and all are underpinned by our managed services.
            </Typography>
            <Typography variant='body1' className='my-2'>
              We focus on customer needs, design functional and user-friendly software that adds value to our customer’s business.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Splash Software provides all the solutions and develop your idea and manage all the side requirements from A to Z, which means you don’t need to have any knowledge or experience in software development.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Software development is the unique ability to visualize A real-world problem and code this into a finely tuned solution. At Splash Software, our highly skilled developers work tirelessly to ensure total customer satisfaction, a work ethic which has made us one of the leading software development companies in UAE.
            </Typography>
            <Typography variant='body1' className='my-2'>
              We provide Application Support across several cloud and on-premises applications across a variety of platforms. Contact us today to find out more about our Application Support for your application or move your current support from another vendor.
            </Typography>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>
              CUSTOM SOFTWARE DEVELOPMENT SOLUTIONS
            </Typography>
            <Typography variant='body1' className='mb-2'>
              More and more businesses are beginning to understand how custom software development can benefit their overall business, improving both internal and external processes and driving value and sales revenue higher. Software is not a ‘one size fits all’ and in many cases a custom application can often be far more productive and provide a better fit within the business environment.
            </Typography>
            <Typography variant='body1' className='my-2'>
              This has been particularly evident with the proliferation of mobile devices, which now enables businesses and their employees to remain connected to backend systems even while out in the field. Having instant access to the latest stock figures and pricing information enables quick decisions to be made and orders to be processed in real time, creating a lasting impression with customers.
            </Typography>
            <Typography variant='body1' className='my-2'>
              These custom applications also provide new capabilities and, by enabling the organization to connect with employees, customers and partners which doing business with them become a pleasure in comparison with the competition.
            </Typography>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>
              SUCCESSFUL SOFTWARE DEVELOPMENT SOLUTIONS
            </Typography>
            <Typography variant='body1' className='mb-2'>
              At Splash Software, we have been successfully developing custom software applications for more than 15 years across desktop, portal and mobile device platforms, during which time we have forged an exceptional reputation for delivering robust software development solutions ahead of time and within budget.
            </Typography>
            <Typography variant='body1' className='my-2'>
              There is a lot more to the development of effective software applications than merely writing good code. There are many good software programmers across the world, but the key limitation is that their expertise lies deep in a coded environment, not within a business environment. Just because a programmer can write good code does not mean they can guarantee an effective application or a positive customer experience.
            </Typography>
            <Typography variant='body1' className='my-2'>
              The key to top-class software development is being able to get inside the mind of your customers, understand exactly what they want and visualize what they are trying to achieve.
            </Typography>
            <Typography variant='body1' className='my-2'>
              It’s about bringing our many years of business and development experience to the fore and demonstrating that we not only understand our customers’ brief, but that they can depend on us to identify and rectify the gaps in their scope that are preventing them from achieving a successful project.
            </Typography>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>
              DISCOVERY IS KEY TO SOFTWARE DEVELOPMENT SERVICES
            </Typography>
            <Typography variant='body1' className='mb-2'>
              Our team’s variety of skillsets have enabled us to deliver the most complex customer solutions that include Web Portal design, Database Middleware design, Web Services, iOS App Design and Android App Design as components of a single project.
            </Typography>
            <Typography variant='body1' className='my-2'>
              They work in programming languages covering C++, C#, .NET, Java, PHP, Swift, Python, Solidity, Go, Ruby on many different software platforms such as Windows, iOS and Android, along with database platforms including MySQL, MS-SQL and NOSQL Databases.
            </Typography>
            <Typography variant='body1' className='my-2'>
              We expert on managing your big data seamlessly on cloud services such as AWS, GCP and Azure.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Before our development team begins to write code, we liaise with the customer in a thorough discovery process which can often incorporate a series of meetings between the project manager and other business stakeholders to identify the full project scope.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Over the course of this process, we utilized visual screen representations explaining how various stakeholders will interact with the final solution. We are happy to invest this comprehensive effort into the process so that stakeholders can identify any aspects which they may have missed.
            </Typography>
            <Typography variant='body1' className='my-2'>
              It also allows for ongoing updating of visuals to incorporate all the necessary functionality. Once completed visuals are signed off, these form the bedrock of the project scope.
            </Typography>
            <Typography variant='body1' className='my-2'>
              From our experience, this process has saved customers six-figure sums of money in potential change requests by helping to pinpoint key functionality that was initially overlooked but is imperative to the success of their project.
            </Typography>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>
              AGILE SOFTWARE DEVELOPMENT SOLUTIONS PRINCIPLES
            </Typography>
            <Typography variant='body1' className='mb-2'>
              At Splash Software, every solution we develop is based on agile design principles with a clear focus on delivering working software.
            </Typography>
            <Typography variant='body1' className='my-2'>
              We encourage the formation of a close relationship from the outset between the development team and the customer, with an emphasis on face-to-face conversations.
            </Typography>
            <Typography variant='body1' className='my-2'>
              As part of the delivery plan, we will have agreed several phased release milestones where the customer can review incremental progress and provide feedback through every stage of the development.
            </Typography>
            <Typography variant='body1' className='my-2'>
              This enables the team to adapt to include any change requests and to maximize the value for the customer. The key objective is to always deliver working software and ultimately deliver a successful project on time and within budget.
            </Typography>
            <Typography variant='body1' className='my-2'>
              Contact us now to speak to a top-class software development company. We are happy to discuss all your software development requirements.
            </Typography>
            <Typography variant='h6' className='mb-2 mt-4 text-gradient d-inline-block'>
              Why Work with Our Software Development Company?
            </Typography>
            <Typography variant='body1' className='mb-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Hundreds of satisfied customers
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Impeccable record of delivering successful projects
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Highly skilled and dynamic development team
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Agile design methodology
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Windows Azure Scalable/Elastic Platform
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>Mobile Application Development Specialists
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>SLAs with Guaranteed Response Times
            </Typography>
            <Typography variant='body1' className='my-2'>
              <MDBIcon icon='chevron-right' className='text-pink px-1'></MDBIcon>24/7/365 Support Services
            </Typography>
            <Typography variant='body1' className='my-3'>
              Let us know if you are seeking an Enterprise Software Development Company that you can trust. Splash Software provides enterprise software development for businesses across the world. We develop enterprise software, which is computer software used to satisfy the needs of an organization’s rather than individual users.
            </Typography>
          </Paper>
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  )
}

export default AboutUs