import React from 'react'
import Typography from '@mui/material/Typography';

const CloudStorage = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Cloud storage is a service model where information is conserved, handled, protected distantly, and offered to customers across a network (typically the Web).
                    In simple language, cloud storage is a virtual hard drive. It is used by different online hosting sites for storing data in virtual storage pools. Cloud hosting allows you to save files and data in an off-site location that you can easily access via the internet. Cloud computing service providers manage and operate data storage. It is delivered whenever demanded, at right time, and eliminates buying of own data storage devices. Cloud storage allows you to access data anytime, anywhere. It gives you the flexibility to scale with durability.
                </p>

                <h5>Public Cloud Storage</h5>
                <p>
                    Public cloud storage services offer a multi-tenant storage ecosystem which is maximum fitted to unstructured information. Information is kept in international data centers along with storage data unfold over various regions or several continents. Users usually do payment over a per-use basis just like the utility payment model.
                </p>

                <h5>Private Cloud Storage</h5>
                <p>
                    Private cloud, or even say on-premises, storage services offer a dedicated atmosphere secured at the back of a company’s firewall. Private clouds are applicable for customers who would like customization and additional management across their information.
                </p>

                <h5>Hybrid Cloud Storage</h5>
                <p>
                    Hybrid cloud happens to be a mixture of private cloud and third-party public cloud based services along with orchestration within the interfaces for maintenance. The respective model provides businesses durability and additional information deployment features.
                </p>
                <h5>Index and Search Integration</h5>
                <p>
                    With the usage of search and categorization attributes customers will be able to punctually obtain their data and necessary files simply.
                </p>
                <h5>High Accessibility</h5>
                <p>
                    Generally the information is recorded various times every group with self-mitigating capacities which is just in situation of sudden drive failure, information are often right away stored once again.
                </p>

                <hr />
                <p>
                    In addition to this, every storage unit is totally secluded from various others making certain it has total protection. With us, customers need not to be concerned and worried regarding the performance, IOPS, security, integrity and protection of their information.
                </p>
            </Typography>
        </>
    )
}

export default CloudStorage