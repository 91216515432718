import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { MDBIcon } from 'mdb-react-ui-kit';
import { isMobile } from 'react-device-detect';

const timelineData = [
    {
        title1: 'Market Research & Analysis Stage',
        title2: 'Step 1',
        align: 'right',
        text: 'This is the first stage of entering into cryptocurrency exchange development. In this stage you should go through a deep research & analysis about the current market of the cryptocurrency trading industry. After the research he/she should find out the loop holes, and failure points that are not focused by the leading player in the cryptocurrency market.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Ideology Stage',
        title2: 'Step 2',
        align: 'left',
        text: 'In this stage you should make an idea based on the research, and should enlarge it with several business modules to stand out from the competitive market place. To beat the competition the idea should be unique.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Plan Deriving Stage',
        title2: 'Step 3',
        align: 'left',
        text: 'Here you should derive a rough cryptocurrency exchange business plan by connecting all the business modules that are listed out during the ideology stage.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Finding The Technical Resources Online',
        title2: 'Step 4',
        align: 'left',
        text: 'After deriving the business plan, you should browse for online resources, technical resources, companies, software services providers, and technology service providers.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Outreach a Cryptocurrency Exchange Development Company',
        title2: 'Step 5',
        align: 'left',
        text: 'Extract the top three cryptocurrency exchange development companies and contact all the chosen companies via direct call, message or email. Analyze how they are responding and choose one company who could meet your requirements both financially and technically. Check out their portfolio, and their growth graph in the cryptocurrency industry.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Escalating The Business Requirements',
        title2: 'Step 6',
        align: 'left',
        text: 'After reaching your desired company, escalate your business requirements, and make a friendly contact with their team & representatives to discuss in detail about your project. ',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Scope Discussion & Finalizing the project',
        title2: 'Step 7',
        align: 'left',
        text: 'Discuss the scope, technical resources, and confirm the project. ',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Business Plan Presentation & Project Team Allocation',
        title2: 'Step 8',
        align: 'left',
        text: 'In this stage the company would bring you a business plan as a road map presentation & let you know the resource allocations. ',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Storyboard Preparation based on the modules',
        title2: 'Step 9',
        align: 'left',
        text: 'After allocating the resources, the escalated business plan will be prototyped as a wire frame. And then all the below process will be done through the waterfall model.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Design Stage',
        title2: 'Step 10',
        align: 'left',
        text: 'The full fledged development process will be done, each design stage will be proceeded into the next stage after the proper confirmation from both client side and the technical team side.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Development Stage',
        title2: 'Step 11',
        align: 'left',
        text: 'Converting the website design into the development process. Proper testing will be proceeded after the completion of each module.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Testing Stage',
        title2: 'Step 12',
        align: 'left',
        text: 'After the completion of the entire website it will be loaded into the local server, to check whether all the functionalities are working properly. If there is any bug occurring, the phase 10 and 11 will be proceed again until solving the bugs. ',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Deployment Phase & additional Changes',
        title2: 'Step 13',
        align: 'left',
        text: 'After testing the developed cryptocurrency exchange website and its functionalities it will be hosted with the support of DevOps Team. Continuous monitoring will be done until there are no additional changes needed from the design and development side.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    }
]

const CryptoExchangeDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Be it centralized or decentralized, Our experienced developers offer all-inclusive crypto exchange development services in Dubai with core functionalities that will enable seamless, secure, instant, and efficient transactions.
                </p>
                <p>
                    Our white-label Bitcoin exchange platform possesses an advanced user interface and has a robust code to prevent any chances of hacks. Our Dubai based white label Bitcoin exchange software development solutions comply with all the relevant legal norms. We ensure high performance by integrating the best technological and security features. It is highly scalable and will work smoothly with different devices and operating systems. The software architecture is established flexibly enabling full customization.
                </p>
            </Typography>
            <Timeline position={isMobile ? '' : 'alternate'}>
                {
                    timelineData.map((timelineItem, i) => <StyledTimelineItem {...timelineItem} key={i}></StyledTimelineItem>)
                }

            </Timeline>
            <Typography variant='body1' className='py-4 text-center'>
                <p>
                    At Splash Software We are offering the industry leading cryptocurrency exchange development development services.
                </p>
            </Typography>
        </>
    )
}

const StyledTimelineItem = (props) => {
    const { align, icon, title1, title2, text, color } = props;
    return <TimelineItem sx={{
        "&:before": {
            display: isMobile ? "none" : ''
        }
    }}>
        {!isMobile &&
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align={align}
                variant="body2"
                color="text.secondary">
                {title2}
            </TimelineOppositeContent>
        }
        <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: color }} />
            <TimelineDot color={color}>
                {icon}
            </TimelineDot>
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                {title1}
            </Typography>
            <Typography variant='body2'>
                {text}
            </Typography>
        </TimelineContent>
    </TimelineItem>
}

export default CryptoExchangeDevelopment