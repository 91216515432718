import React from 'react'
import Typography from '@mui/material/Typography';

const NFTDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    We help you launch your own NFT marketplace and attract the growing community of NFT users. NFT marketplaces built by us are feature-rich, decentralized, and facilitate tokenization of all kinds of assets, including artwork,  software licenses, digital collectibles, and real-world assets.
                </p>

                <h5>NFT Marketplace Development</h5>
                <p>
                    Launch an NFT marketplace for digital assets, music, game content, etc, complete with a wallet that supports multiple cryptos, social features, and a streamlined buyer and seller experience.
                </p>
                <h5>NFT Smart Contracts Auditing</h5>
                <p>
                    Subject all your smart contracts to comprehensive auditing with both automated testing and manual review, to ensure minimal bugs, errors, and breaches.
                </p>
                <h5>NFT Support and Maintenance</h5>
                <p>
                    Keep your NFT platform up to date with novel features and content updates, and reduce downtime via routine monitoring.
                </p>
                <h5>NFT for Subscriptions</h5>
                <p>
                    Gate your entire content library or a part of it with an NFT based paid subscription, so only verified users have access to it.
                </p>
                <h5>NFT for Software Licensing</h5>
                <p>
                    Create transferable and non-transferable licenses that are secure, private, and piracy resistant by default, for all your software.
                </p>
                <h5>NFT Tokenization</h5>
                <p>
                    Create custom tokens based on different blockchains and also enable your users to mint their own NFTs within your platform.
                </p>
                <h5>NFT Marketplace Design and Development</h5>
                <p>
                    With in-depth knowledge of ERC-721 and ERC-1155 standards, smart contracts and IPFS protocols, our team designs and builds a user-centric NFT marketplace platform where users can create and trade NFTs.
                </p>
                <h5>NFT Smart Contract Development and Audit</h5>
                <p>
                    We offer NFT smart contract development and audit services to ensure the error-free functioning of the smart contracts, ensuring seamless automation in NFT transactions.
                </p>
                <h5>NFT Marketplace Support and Maintenance</h5>
                <p>
                    We continuously monitor, maintain and offer support for managing third-party upgrades, new OS releases and ensure nodes are always up and running.
                </p>
                <h5>NFT Development</h5>
                <p>
                    Our NFT development team provides a token creation feature as a service to your NFT marketplace. It allows users on the platform to mint tokens for their assets.
                </p>
                <h5>NFT STORAGE SOLUTION</h5>
                <p>
                    Our NFT storage system, built specifically for storing off-chain NFT data, helps creators and NFT marketplace owners store data in a decentralized way on IPFS.
                </p>

            </Typography>
            {/* <Timeline position={isMobile ? '' : 'alternate'}>
                {
                    timelineData.map((timelineItem, i) => <StyledTimelineItem {...timelineItem} key={i}></StyledTimelineItem>)
                }
            </Timeline> */}
        </>
    )
}

export default NFTDevelopment