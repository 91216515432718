import { Paper, Typography } from '@mui/material'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import CryptoExchangeDevelopment from './blockchain/CryptoExchangeDevelopment';
import NFTDevelopment from './blockchain/NFTDevelopment';
import SmartContractDevelopment from './blockchain/SmartContractDevelopment';
import TokenDevelopment from './blockchain/TokenDevelopment';
import ArtificialIntelligence from './cloud/ArtificialIntelligence';
import MachineLearning from './cloud/MachineLearning';
// import DevelopCloudStrategy from './cloud/DevelopCloudStrategy';
import SaaS from './cloud/SaaS';
import BigData from './data/BigData';
// import CognitiveSearch from './cloud/CognitiveSearch';
import DataAnalysis from './data/DataAnalysis';
import CloudStorage from './data/CloudStorage';
import GameDevelopment from './gaming/GameDevelopment';
import WebDevelopment from './web/WebDevelopment';
import UIUXDesign from './web/UIUXDesign';
import MobileDevelopment from './mobile/MobileDevelopment';

const Page = (props) => {
    const { category, service } = props.match.params;
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <MDBContainer fluid>
                <MDBRow className='m-0 pb-4'>
                    <MDBCol size='12' className='px-0 px-md-2'>
                        <Typography className='text-center py-5 text-white' variant='h4'>{service}</Typography>
                        <Paper
                            className='py-4 px-3'
                            sx={{
                                color: '#aaa !important',
                                backgroundColor: '#050505',
                                boxShadow: '0 0 10px #fff6',
                                '& h5': {
                                    color: '#ddd !important',
                                    paddingTop: 3
                                },
                            }}>
                            {/* Blockchain Services */}
                            {service && service.trim().toLowerCase() === 'crypto exchange development' && <CryptoExchangeDevelopment></CryptoExchangeDevelopment>}
                            {service && service.trim().toLowerCase() === 'coin and token development' && <TokenDevelopment></TokenDevelopment>}
                            {service && service.trim().toLowerCase() === 'smart contract development' && <SmartContractDevelopment></SmartContractDevelopment>}
                            {service && service.trim().toLowerCase() === 'nft development' && <NFTDevelopment></NFTDevelopment>}
                            {/* Cloud Services */}
                            {service && service.trim().toLowerCase() === 'artificial intelligence' && <ArtificialIntelligence></ArtificialIntelligence>}
                            {service && service.trim().toLowerCase() === 'machine learning' && <MachineLearning></MachineLearning>}
                            {service && service.trim().toLowerCase() === 'software as a service' && <SaaS></SaaS>}
                            {/* {service && service.trim().toLowerCase() === 'develop cloud strategy' && <DevelopCloudStrategy></DevelopCloudStrategy>} */}
                            {/* {service && service.trim().toLowerCase() === 'cognitive search' && <CognitiveSearch></CognitiveSearch>} */}
                            {/* Data Services */}
                            {service && service.trim().toLowerCase() === 'big data' && <BigData></BigData>}
                            {service && service.trim().toLowerCase() === 'cloud storage' && <CloudStorage></CloudStorage>}
                            {service && service.trim().toLowerCase() === 'data analysis' && <DataAnalysis></DataAnalysis>}

                            {/* Gaming Solutions */}
                            {/* {service && service.trim().toLowerCase() === 'modeling' && <GameDevelopment></GameDevelopment>} */}
                            {service && service.trim().toLowerCase() === 'game development' && <GameDevelopment></GameDevelopment>}
                            {service && service.trim().toLowerCase() === 'cloud storage' && <CloudStorage></CloudStorage>}
                            {service && service.trim().toLowerCase() === 'data analysis' && <DataAnalysis></DataAnalysis>}

                            {/* Web Development */}
                            {service && service.trim().toLowerCase() === 'website development' && <WebDevelopment></WebDevelopment>}
                            {service && service.trim().toLowerCase() === 'website ui ux design' && <UIUXDesign></UIUXDesign>}

                            {/* Mobile Development */}
                            {service && category && category.trim().toLowerCase() === 'mobile' && service.trim().toLowerCase() === 'app development' && <MobileDevelopment></MobileDevelopment>}
                            {service && service.trim().toLowerCase() === 'mobile ui ux design' && <UIUXDesign></UIUXDesign>}

                            {/* Software Development */}
                            {service && service.trim().toLowerCase() === 'big data' && <BigData></BigData>}
                            {service && service.trim().toLowerCase() === 'cloud storage' && <CloudStorage></CloudStorage>}
                            {service && service.trim().toLowerCase() === 'data analysis' && <DataAnalysis></DataAnalysis>}
                        </Paper>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

        </>
    )
}

export default Page