import React from 'react';
import './styles/index.scss';
import App from './App';
import { CookiesProvider } from 'react-cookie';
import Store from './store';

// After
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(<Store>
  <CookiesProvider>
    <App />
  </CookiesProvider>
</Store>);