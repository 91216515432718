import React from 'react'
import Typography from '@mui/material/Typography';

const UIUXDesign = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Splash Software has the team of professionals who are creative thinkers and are attuned to your needs. When we work on website designing, we also ensure that your brand is reflected in your web presence.
                    We understand the importance of good design in digital success. Our people-first, appealing, and pleasant designs address the needs of modern businesses and enterprises. We offer comprehensive UI/UX services that include branding, responsive web design, mobile app design, user experience consulting, and promotional designs using the latest tools and technologies.
                </p>
                <p>
                    With a unique blend of modern technologies and innovative approaches, we create responsive and adaptive designs with uniform cross-platform compatibility. With over two decades of experience in the design industry, we are known for our creative passion and hands-on experience in all aspects of UI/UX. We understand what it takes to build a brand from the design perspective and help deliver the same using modern tools and ways of thinking. We help clients thrive by simplifying designs that can make a lasting impact on the mind of the users which in turn, increase brand awareness and loyalty.
                </p>
                <h5>Excellent User Experience</h5>
                <p>
                    After all, that is what our designs are aimed at - providing the best-in-class user experience and user interface to our global customers.                </p>
                <h5>Immense Industry Expertise</h5>
                <p>
                    A three-decade-long IT industry experience, in diverse technologies, is proof enough to showcase our successful span, across the globe.                </p>
                <h5>User-led Strategy</h5>
                <p>
                    We ensure our customers get the best of design services that revolve around users, simplicity, and usability.                </p>
                <h5>Affordable and Timely</h5>
                <p>
                    With close collaborations and innovative ideas, our design solutions are affordable, timely, and effective to grow your user base.                </p>
                <h5>Web Quality Assurance</h5>
                <p>
                    Take advantage of a full range of QA services to ensure the stable operation of your web apps and presences and detect bugs before going to production
                </p>
                <h5>Web Integration</h5>
                <p>
                    If your web project is data-intensive and needs to be integrated with third-party web presences or apps, Splash Software will assist you with such integrations
                </p>
                <hr />
                <p>
                    Having an impressive UI goes much beyond capturing the attention of the user; it is capable of creating outstanding UX and building the business brand. Hence, every business needs to focus on creating immersive UI designs that drive improved UX.
                </p>
                <p>
                    At Splash Software, we comprehend the value of user-centered designs and understand the impact these have on the user experience. We make sure that users’ perspectives form the core of our development process so that we are able to deliver enhanced usability in each of the products we create.

We have a team of expert UI/UX designers who focus on the user expectations and incorporate the UX design practice in each stage of the app development cycle. We specialize in creating interfaces that are aesthetically appealing, easy to navigate, and truly user-friendly. Our UI design services enable our clients to avail a scalable and robust user experience, which maximizes their ROI and promotes their branding strategy.
                </p>
            </Typography>
        </>
    )
}

export default UIUXDesign