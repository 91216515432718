import React from 'react'
import { Avatar, Box,  Typography } from '@mui/material';
import EmailSharpIcon from '@mui/icons-material/Email';
import PhoneSharpIcon from '@mui/icons-material/PhoneSharp';
import PhoneIphone from '@mui/icons-material/PhoneIphone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Fab from '@mui/material/Fab';
import 'animate.css';

const QRList = [
    {
        id: 0,
        title: 'Title',
        avatar: '',
        email: '',
        linkedin: '',
        phone: '',
        position: '',
        description: '',
        whatsapp: '',
        telegram: ''
    },
    {
        id: 3,
        name: 'Oksana T.',
        title: 'Administration Manager',
        avatar: '',
        email: 'oksana@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        mobile: '+971 (52) 142 0840',
        position: '',
        description: '',
        whatsapp: '',
        telegram: '',
        address: 'No A502, Opus Tower, Dubai - UAE'
    },
    {
        id: 2,
        name: 'Salman S.',
        title: 'CIO',
        avatar: '',
        email: 'salman@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        position: '',
        description: '',
        whatsapp: '',
        telegram: ''
    },
    {
        id: 3,
        name: 'C. Diego',
        title: 'Managing Director',
        avatar: '',
        email: 'diego@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        position: '',
        description: '',
        whatsapp: '',
        telegram: ''
    },
    {
        id: 4,
        name: 'Renata S.',
        title: 'Legal Director',
        avatar: '/staff/renata.jpg',
        email: 'renata@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        mobile: '+971 (56) 220 7117',
        position: '',
        description: '',
        whatsapp: '',
        telegram: '',
        address: 'No A502, Opus Tower, Dubai - UAE'
    },
    {
        id: 5,
        name: 'Darrin R.',
        title: 'Business Development',
        avatar: '/staff/darrin.jpg',
        email: 'darrin@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        mobile: '+971 (58) 531 0422',
        position: '',
        description: '',
        whatsapp: '',
        telegram: '',
        address: 'No A502, Opus Tower, Dubai - UAE'
    },
    {
        id: 6,
        // name: 'Darrin R.',
        title: 'Game Development',
        avatar: '/logo/avatar.png',
        email: 'sales@spls.ae',
        linkedin: '',
        phone: '+971 (04) 456 1660',
        position: '',
        description: '',
        whatsapp: '',
        telegram: '',
        address: 'No A502, Opus Tower, Dubai - UAE'
    }
]


const QR = (props) => {
    const { id } = props.match.params;
    return <Box className='mt-4 pb-4 d-flex flex-column justify-content-center align-items-center m-auto rounded-9 overflow-hidden'
        sx={{
            bgcolor: '#0003',
            boxShadow: '0 0 10px #f0f5',
            width: ['100vw', '80vw', '40vw', '25vw', '20vw']
        }}>
        <Box className='bg-gradient w-100 d-flex justify-content-center align-items-baseline'
            sx={{ height: '75px', marginBottom: '37.5px' }}>
            <Avatar
                src={QRList[id].avatar}
                className=' _animate__animated animate__zoomIn'
                sx={{ border: '2px solid black', marginTop: '37.5px', width: '75px', height: '75px' }}></Avatar>
        </Box>

        <Typography variant='h5' className='_animate__animated animate__fadeInRight  animate__delay-02s'>
            {QRList[id].name}
        </Typography>
        <Typography variant={QRList[id].name ? 'body2' : 'h5'} className='text-gradient _animate__animated animate__fadeInDown animate__delay-04s'>
            {QRList[id].title}
        </Typography>
        <Box className='w-100'>
            <hr className='bg-gradient' />
        </Box>
        <Box className='text-start w-100 px-3'>
            <Box className='d-flex align-items-center _animate__animated animate__fadeInUp animate__delay-06s  py-2' >
                <Fab size='small' className='me-2 bg-gradient'>
                    <Box className='text-white rounded-circle bg-black overflow-hidden w-100 h-100 d-flex align-items-center justify-content-center' sx={{ margin: '1px' }}>
                        <EmailSharpIcon sx={{ width: '0.9rem' }} />
                    </Box>
                </Fab>
                <Box className='d-flex flex-column justify-content-center' sx={{ lineHeight: '0.1rem' }}>
                    <Typography variant='subtitle2' className='opacity-60' sx={{ lineHeight: '0.5rem' }}>
                        Email
                    </Typography>
                    <Typography className='' sx={{ lineHeight: '1rem' }}>
                        {QRList[id].email}
                    </Typography>
                </Box>
            </Box>
            {
                QRList[id].phone &&
                <Box className='d-flex align-items-center _animate__animated animate__fadeInUp animate__delay-06s py-2' >
                    <Fab size='small' className='me-2 bg-gradient'>
                        <Box className='text-white rounded-circle bg-black overflow-hidden w-100 h-100 d-flex align-items-center justify-content-center' sx={{ margin: '1px' }}>
                            <PhoneSharpIcon sx={{ width: '0.9rem' }} />
                        </Box>
                    </Fab>

                    <Box className='d-flex flex-column justify-content-center' sx={{ lineHeight: '0.1rem' }}>
                        <Typography variant='subtitle2' className='opacity-60' sx={{ lineHeight: '0.5rem' }}>
                            Phone
                        </Typography>
                        <Typography className='' sx={{ lineHeight: '1rem' }}>
                            {QRList[id].phone}
                        </Typography>
                    </Box>
                </Box>
            }
            {
                QRList[id].mobile &&
                <Box className='d-flex align-items-center _animate__animated animate__fadeInUp animate__delay-06s py-2' >
                    <Fab size='small' className='me-2 bg-gradient'>
                        <Box className='text-white rounded-circle bg-black overflow-hidden w-100 h-100 d-flex align-items-center justify-content-center' sx={{ margin: '1px' }}>
                            <PhoneIphone sx={{ width: '0.9rem' }} />
                        </Box>
                    </Fab>

                    <Box className='d-flex flex-column justify-content-center' sx={{ lineHeight: '0.1rem' }}>
                        <Typography variant='subtitle2' className='opacity-60' sx={{ lineHeight: '0.5rem' }}>
                            Mobile
                        </Typography>
                        <Typography className='' sx={{ lineHeight: '1rem' }}>
                            {QRList[id].mobile}
                        </Typography>
                    </Box>
                </Box>
            }
            {
                QRList[id].address &&
                <Box className='d-flex align-items-center _animate__animated animate__fadeInUp animate__delay-06s py-2' >
                    <Fab size='small' className='me-2 bg-gradient'>
                        <Box className='text-white rounded-circle bg-black overflow-hidden w-100 h-100 d-flex align-items-center justify-content-center' sx={{ margin: '1px' }}>
                            <LocationOnIcon sx={{ width: '0.9rem' }} />
                        </Box>
                    </Fab>

                    <Box className='d-flex flex-column justify-content-center' sx={{ lineHeight: '0.1rem' }}>
                        <Typography variant='subtitle2' className='opacity-60' sx={{ lineHeight: '0.5rem' }}>
                            Address
                        </Typography>
                        <Typography className='' sx={{ lineHeight: '1rem' }}>
                            {QRList[id].address}
                        </Typography>
                    </Box>
                </Box>
            }
        </Box>
        {/* <Box sx={{ '& > :not(style)': { m: 1 } }}>

            <Fab sx={{ bgcolor: green[700] }} size='small'>
                <WhatsAppIcon />
            </Fab>
            <Fab variant="extended">
                <NavigationIcon sx={{ mr: 1 }} />
                Navigate
            </Fab>
            <Fab disabled aria-label="like">
                <FavoriteIcon />
            </Fab>
        </Box> */}
    </Box>
}

export default QR