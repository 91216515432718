import React, { useEffect } from 'react'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MDBCol, MDBRow } from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

const LobbySplash = () => {

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);
        let intro = gsap.timeline();
        intro
            .from("#Splash-BG", { lazy: false, opacity: 1, duration: 0.6 })
        // .from(".t1", { lazy: false, y: "-3vh", opacity: 0, duration: 0.6 }, 1)
        // .from(".t3", { lazy: false, y: "-2vh", opacity: 0, duration: 0.6 }, 2);

        let outro = gsap.timeline({
            scrollTrigger: {
                trigger: ".hero",
                start: "top top",
                scrub: 0.3,
            }
        });
        outro
            .to("#Splash-BG", { lazy: false, opacity: 0.3, duration: 0.3 }, 0)
            // .to(".t1-inner", { lazy: false, y: "-55vh", scale: 0.96, opacity: 0, duration: 0.3 }, 0)
            // .to(".t3-inner", { lazy: false, y: "-48vh", scale: 0.94, opacity: 1, duration: 0.3 }, 0)
            // .to(".t4", { lazy: false, y: "-120vh", scale: 0.94, opacity: 1, duration: 0.3 }, 0)
            ;
    }, [])

    return (
        <>
            <div>
                <div className="hero overflow-hidden position-relative text-center d-flex justify-content-around align-items-center" style={{
                    width: '100vw',
                    height: '100vh'
                }}>
                    <div className="position-absolutem-auto pb-5">
                        <div className="max-w-3xl mx-auto text-center">
                            <div className="t1 mb-2 text-white">
                                <div className="t1-inner">
                                    <Typography sx={{ fontWeight: '500 !important', typography: { xs: 'h3', md: 'h1' } }}>Splash Software</Typography>
                                </div>
                            </div>
                            <div className="t3 text-white">
                                <div className="t3-inner">
                                    <Typography sx={{ typography: { xs: 'h6', md: 'h4' } }}>Where Innovation Meets The Real World</Typography>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <MDBRow className='m-0 t4'>
                <MDBCol size='12' className='text-center py-5 rounded-6 my-3' style={{
                    boxShadow: '0 0 5px #ffffff33',
                    background: '#00000055'
                }} >
                    <h4 className='pb-4'>Blockchain Services</h4>
                    <MDBRow className='m-0'>
                        <LobbyServiceBox
                            title='NFT Token Development'
                            text='We help you launch your own NFT marketplace and attract the growing community of NFT users. NFT marketplaces built by us are feature-rich, decentralized, and facilitate tokenization of all kinds of assets, including artwork,  software licenses, digital collectibles, and real-world assets.'
                            size='3'
                            url='/Services/Blockchain/NFT Development'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Coin and Token Development'
                            text='Be it centralized or decentralized, Our experienced developers offer all-inclusive crypto exchange development services in Dubai with core functionalities that will enable seamless, secure, instant, and efficient transactions.

                            Our white-label Bitcoin exchange platform possesses an advanced user interface and has a robust code to prevent any chances of hacks. Our Dubai based white label Bitcoin exchange software development solutions comply with all the relevant legal norms. We ensure high performance by integrating the best technological and security features. It is highly scalable and will work smoothly with different devices and operating systems. The software architecture is established flexibly enabling full customization.'
                            size='3'
                            url='/Services/Blockchain/Coin and Token Development'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Smart Contracts Development'
                            text='By bringing transparency, auditability, and speed to outdated workflows, smart contracts can change the face of many global industries. Take a look at the most prominent use cases.'
                            size='3'
                            url='/Services/Blockchain/Smart Contract Development'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Crypto Exchange Development'
                            text='Our white-label Bitcoin exchange platform possesses an advanced user interface and has a robust code to prevent any chances of hacks. Our Dubai based white label Bitcoin exchange software development solutions comply with all the relevant legal norms. We ensure high performance by integrating the best technological and security features. It is highly scalable and will work smoothly with different devices and operating systems. The software architecture is established flexibly enabling full customization.'
                            size='3'
                            url='/Services/Blockchain/NFT Development'>
                        </LobbyServiceBox>
                    </MDBRow>
                </MDBCol>

                <MDBCol size='12' className='text-center py-5 rounded-6 my-3' style={{
                    boxShadow: '0 0 5px #ffffff33',
                    background: '#00000055'
                }} >
                    <h4 className='pb-4'>Cloud Services</h4>
                    <MDBRow className='m-0'>
                        <LobbyServiceBox
                            title='Machine Learning'
                            text='To avoid mistakes when developing custom machine learning , it is helpful to outline the steps involved in this work.
                            Since the duration and complexity of the development of machine learning systems, depending on the requirements, may differ by several orders of magnitude, the first step is to fix the desired result that the customer wants to get.
                            Having finished with the formulation of the problem and having roughly decided on the solution (and also making sure that the problem can be solved within a reasonable time frame and budgets), we proceed to the next step: collecting data.'
                            size='4'
                            url='/Services/Cloud/Machine Learning'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Artificial Intelligence'
                            text='Our team of data engineers and developers build custom AI software solutions to create a sustainable and cohesive AI-driven ecosystem for startups, small-midsize businesses, and enterprise-wide businesses.'
                            size='4'
                            url='/Services/Cloud/Artificial Intelligence'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Software as a Service'
                            text='We develop SaaS and Cloud based Software Applications based on your business requirements. The core idea of Saas is to provide companies with tools to develop and maintain their products without building a custom tech skeleton from scratch.
                            Developing a SaaS application might be the logical next step on your way to business growth. But building software isn’t a decision you can make lightly or without conducting proper research first. You need to understand the ins and outs of the SaaS development process to make proper decisions when it comes to hiring a dev team, allocating the budget, or marketing the new solution. And this is what this article is about.'
                            size='4'
                            url='/Services/Cloud/Software as a Service'>
                        </LobbyServiceBox>
                    </MDBRow>
                </MDBCol>

                <MDBCol size='12' className='text-center py-5 rounded-6 my-3' style={{
                    boxShadow: '0 0 5px #ffffff33',
                    background: '#00000055'
                }} >
                    <h4 className='pb-4'>Data Services</h4>
                    <MDBRow className='m-0'>
                        <LobbyServiceBox
                            title='Cloud Storage'
                            text='Cloud storage is a service model where information is conserved, handled, protected distantly, and offered to customers across a network (typically the Web). In simple language, cloud storage is a virtual hard drive. It is used by different online hosting sites for storing data in virtual storage pools. Cloud hosting allows you to save files and data in an off-site location that you can easily access via the internet. Cloud computing service providers manage and operate data storage. It is delivered whenever demanded, at right time, and eliminates buying of own data storage devices. Cloud storage allows you to access data anytime, anywhere. It gives you the flexibility to scale with durability.'
                            size='4'
                            url='/Services/Data/Cloud Storage'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Data Analysis'
                            text='Error creeps into data every step along the way as it makes it way to the business and analytics team for usage and insights. Companies are seeing an increase in data volume, data complexity, number of data sources and number of platforms (Lake, Cloud, Cloud Warehouses, Hadoop).'
                            size='4'
                            url='/Services/Data/Data Analysis'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Big Data'
                            text='Big data is an important process of discovering hidden patterns, correlations, market trends and customer preferences for companies. Organisations can benefit from big data analytics systems and software to make decisions that can improve their business outcome.'
                            size='4'
                            url='/Services/Data/Big Data'>
                        </LobbyServiceBox>
                    </MDBRow>
                </MDBCol>

                <MDBCol size='12' className='text-center py-5 rounded-6 my-3' style={{
                    boxShadow: '0 0 5px #ffffff33',
                    background: '#00000055'
                }} >
                    <h4 className='pb-4'>Mobile Development</h4>
                    <MDBRow className='m-0'>
                        <LobbyServiceBox
                            title='App Development'
                            text='Being a team of Mobile experts, Splash Software can deliver every aspect of a Mobile project, e.g. analysis, design, development, testing, and more. We provide expert-level Mobile development services to turn your vision into a high-quality Mobile-based solution, exactly the way you envisioned'
                            size='6'
                            url='/Services/Mobile/App Development'>
                        </LobbyServiceBox>
                        <LobbyServiceBox
                            title='Mobile UI UX Design'
                            text='Splash Software has the team of professionals who are creative thinkers and are attuned to your needs. When we work on website designing, we also ensure that your brand is reflected in your web presence. We understand the importance of good design in digital success. Our people-first, appealing, and pleasant designs address the needs of modern businesses and enterprises. We offer comprehensive UI/UX services that include branding, responsive web design, mobile app design, user experience consulting, and promotional designs using the latest tools and technologies.'
                            size='6'
                            url='/Services/Mobile/Mobile UI UX Design'>
                        </LobbyServiceBox>
                    </MDBRow>
                </MDBCol>
            </MDBRow>
        </>
    )
}

const LobbyServiceBox = (props) => {
    const { title, text, size, url } = props;
    return <MDBCol size='12' md={size || '4'} className='pb-4 serviceItem' style={{}}>
        <Box className='rounded-6 h-100 p-4' sx={{ boxShadow: '0 0 5px #ffffff33', background: '#00000055' }}>
            <div className='text-start text-md-start pb-2'>
                <Typography className='text-gradient' variant='h6' sx={{
                    display: 'inline-block',
                    textAlign: 'center'
                }}>{title}</Typography>
            </div>
            <Typography variant='body1' className='text-start overflow-hidden' sx={{
                maxHeight: '5.2rem'
            }}>{text}</Typography>
            <div className='text-end small pt-3'>
                <Link to={url} className='text-pink'>Read More ...</Link>
            </div>
        </Box>
    </MDBCol>
}

export default LobbySplash