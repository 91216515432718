import axios from 'axios';
import React, { useState, useEffect, useContext } from 'react'
import { getBearer } from '../helpers/publicHelper';
import { Context } from '../store';

const FileUpload = (props) => {
    const { onChange, onUploaded, onUploading, params, onError, removeFiles, ...other } = props;
    const [state, dispatch] = useContext(Context);
    const [files, setFiles] = useState([]);

    const handleChange = (e) => {
        if (onChange) {
            onChange()
        }
        const files = e.target.files;
        setFiles(files)
    }

    // useEffect(() => {
    //     console.log('removeFiles', removeFiles)
    //     console.log('removeFiles', files)
    //     if (removeFiles) {
    //         setFiles([])
    //     }
    // }, [removeFiles])


    useEffect(() => {

        if (files.length >= 1) {
            onUploading && onUploading();
            let data = new FormData();
            for (let index = 0; index < files.length; index++) {
                data.append('file', files[index]);
            }
            data.append('params', JSON.stringify(params));
            const headers = { 'x-auth-token': getBearer(), "Content-Type": "multipart/form-data" };
            axios.post(state.active.host + '/api/storage/upload', data, {
                headers: headers
            })
                .then(res => {
                    res = res.data;
                    if (res.success) {
                        onUploaded(res.result);
                    } else {
                        onError(res.result)
                    }
                })
                .catch(err => {
                    console.log(err)
                    onError('Upload error.')
                })
        }

    }, [files])

    return (
        <div>
            <input {...other}
                // value={files}
                onChange={handleChange} accept="image/jpg,image/jpeg,image/png,application/pdf," id="icon-button-file" type="file" />
        </div>
    )
}

export default FileUpload
