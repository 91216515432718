import React from 'react';
import LayoutDrawer from './LayoutDrawer';
import theme from './DefaultTheme';
import { SnackbarProvider } from 'notistack';
import { ThemeProvider, Zoom } from '@mui/material';

const Layout = (props) => {
  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        autoHideDuration={1500}
        TransitionComponent={Zoom}
        maxSnack={1}>
        <LayoutDrawer>
          {props.children}
        </LayoutDrawer>
      </SnackbarProvider>
    </ThemeProvider>
  )
}

export default Layout
