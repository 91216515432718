import React, { useContext, useEffect } from 'react'
import { Context } from './store';
import { Box } from '@mui/system';
import LobbySplash from './lobby/LobbySplash';
import { useLocation } from 'react-router-dom';

const Lobby = () => {

    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (<>
        <Box sx={{
            backgroundSize: 'cover'
        }}>
            <LobbySplash></LobbySplash>
        </Box>


    </>
    )
}

export default Lobby
