import { Accordion, AccordionDetails, AccordionSummary, Chip, Divider, Drawer, List, ListItem, Modal, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { MDBCol, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { useLocation } from 'react-router-dom';
import { vacancies } from './JD';
import ThemeButton from '../../layout/components/ThemeButton';
import HiringForm from './HiringForm';

const Hiring = () => {
  const [modalOpen, setModalOpen] = useState('')
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <MDBRow className='m-0 justify-content-center'>
      <MDBCol size='12'>
        <Typography className='text-center py-5' variant='h4'>We Are Hiring</Typography>
      </MDBCol>
      <MDBCol size='12' md='6'>
        <Box className='p-3 bg-black rounded-6 mb-4' sx={{
          background: '#000a',
          boxShadow: '0 0 5px #fff5'
        }} >
          <Intro />
          <Vacancies modalOpen={modalOpen} setModalOpen={setModalOpen} />
        </Box>
      </MDBCol>
      <Modal
        open={modalOpen != ''}
        onClose={() => setModalOpen('')}
        onBackdropClick={() => setModalOpen('')}
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            borderRadius: '15px',
            overflow: 'hidden',
            width: ['92vw', '80vw', '1000px'],
            bgcolor: '#000',
            boxShadow: '0 0 10px #1af',

          }}
          className='text-center rounded-6'>

          <Toolbar disableGutters>
            <Typography variant='h6' className='text-gradient font-weight-bold w-100 text-start p-2' sx={{
              borderBottom: '1px solid #444'
            }}>Send your CV and apply</Typography>
            <Box
              onClick={() => setModalOpen('')}
              sx={{
                p: 2,
                display: 'block',
                position: 'absolute',
                right: 0,
              }}>
              <MDBIcon
                icon='times' className='text-white cursor-pointer' />
            </Box>
          </Toolbar>
          <Box sx={{
            height: 'fit-content',
            maxHeight: '70vh',
            overflow: 'scroll'
          }}>
            <HiringForm activePosition={modalOpen} />
          </Box>
        </Box>
      </Modal>
    </MDBRow>
  )
}

const Vacancies = ({ modalOpen, setModalOpen }) => {
  return <Box>
    {
      vacancies.map((vacancy, i) => <VacancyItem key={i} {...vacancy} setModalOpen={setModalOpen} />)
    }
  </Box>

}

const VacancyItem = (props) => {
  const {
    jobTitle,
    responsibilities,
    requirements,
    skills,
    preferredSkills,
    languages,
    workingTime,
    jobType,
    gender,
    // nationalities,
    salary,
    location,
    setModalOpen
  } = props;



  const [open, setOpen] = useState(false)
  return <Box className='' sx={{
    width: '100%'
  }}>
    <Accordion className='p-0 bg-black rounded-0' sx={{
      background: '#000a',
      borderTop: '1px solid #333'
      // boxShadow: '0 0 5px #fff5'
    }} expanded={open}
      onChange={() => setOpen(!open)}
    >
      <AccordionSummary expandIcon={<MDBIcon icon='chevron-down'></MDBIcon>}>
        <Typography variant='h6' className='text-gradient font-weight-bold'>{jobTitle}</Typography>
      </AccordionSummary>
      <AccordionDetails className='p-0 m-0'>
        <Box sx={{
          background: '#000a',
        }} className='rounded-6 px-2 px-md-3 text-gray'>


          {responsibilities.length > 0 && <>
            <Typography variant='h6' className='text-pink mt-2'>Responsibility</Typography>
            <List className='p-0 m-0 mb-2'>
              {responsibilities.map((item, i) =>
                <ListItem key={i} className='ps-1 d-block'>
                  <Typography variant={isMobile ? 'subtitle1' : 'body1'}>
                    <MDBIcon icon='chevron-right' className='text-pink pe-1'></MDBIcon> {item}
                  </Typography>
                </ListItem>
              )}
            </List>
            <Divider></Divider>
          </>}

          {requirements.length > 0 && <>
            <Typography variant='h6' className='text-pink mt-2'>Requirements</Typography>
            <List className='p-0 m-0 mb-2'>
              {requirements.map((item, i) =>
                <ListItem key={i} className='d-block p-0 pb-1'>
                  <Typography variant={isMobile ? 'subtitle1' : 'subtitle1'}>
                    <MDBIcon icon='chevron-right' className='text-pink pe-1'></MDBIcon> {item}
                  </Typography>
                </ListItem>
              )}
            </List>
            <Divider></Divider>
          </>}

          <Typography variant='h6' className='text-pink mt-2'>Details</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center'><MDBIcon icon='language' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Languages: {languages[0]}</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center'><MDBIcon icon='clock' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Working Time: {workingTime}</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center'><MDBIcon icon='calendar-alt' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Job Type: {jobType}</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center'><MDBIcon icon='id-badge' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Gender: {gender}</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center'><MDBIcon icon='dollar-sign' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Salary: {salary}</Typography>
          <Typography variant='body2' className='py-1 d-flex align-items-center mb-3'><MDBIcon icon='map-marker-alt' className='text-pink' style={{ width: '1.2rem' }}></MDBIcon> Location: {location}</Typography>

          <Divider></Divider>
          {
            skills && <>
              {(skills.length > 0) && <>
                <Typography variant='h6' className='text-pink my-2'>Rquired Skills</Typography>
                <List className='p-0 m-0 mb-2'>
                  {skills.map((item, i) =>
                    <Chip key={i} label={item} variant="outlined" className='me-1 mb-1' size='small' />
                  )}
                </List>
                <Divider />
              </>}
            </>
          }


          {preferredSkills && preferredSkills.length > 0 && <>
            <Typography variant='h6' className='text-pink my-2'>Preferred Skills</Typography>
            <List className='p-0 m-0 mb-2'>
              {preferredSkills.map((item, i) =>
                <Chip key={i} label={item} variant="outlined" className='me-1 mb-1' size='small' />
              )}
            </List>
          </>}
          <Divider />
          <Box className='text-center'>
            <ThemeButton onClick={() => setModalOpen(jobTitle)} color='success' variant='outlined' className='rounded-8 box-shadow- py-2 px-3 m-2'>
              <Box className='d-flex justify-content-center align-items-center'>
                <span className='pe-2 me-2 border-end border-success'>Apply</span>
                <small className='opacity-50'>{jobTitle}</small>
              </Box>
            </ThemeButton>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>

  </Box>
}

const Intro = () => {
  return <Box className='text-start pb-3'>
    <Typography variant='body1'>
      We believe that work should be a fun and challenging place. Our employees are learning new things on an everyday basis as well as participating in development and decision making to their fullest capacity.
      If you are a uniquely talented individual who enjoys working with other people and are enthusiastic about mobile development then Splash Software is the right place for you. We offer the challenge you are looking for as well as the atmosphere that will make your work experience worthwile.
    </Typography>
  </Box>
}

export default Hiring