import React from "react";
import { Typography } from "@mui/material";
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";
import $ from 'jquery'
import { blockchainServices, cloudServices, dataServices, mobileDevelopment, webDevelopment } from "../helpers/menuData";


const footerLists = [
    // {
    //     title: 'Splash Software',
    //     list: splashSoftware
    // },
    {
        title: 'Blockchain Services',
        list: blockchainServices
    },
    {
        title: 'Cloud Services',
        list: cloudServices
    },
    {
        title: 'Data Services',
        list: dataServices
    },
    // {
    //     title: 'Gaming Solutions',
    //     list: gamingSolutions
    // },
    {
        title: 'Web Development',
        list: webDevelopment
    },
    {
        title: 'Mobile Development',
        list: mobileDevelopment
    },
    // {
    //     title: 'Software Development',
    //     list: softwareDevelopment
    // },
]

const Footer = () => {

    return (
        <div className="bg-black">
            <div
                style={{
                    display: 'block',
                    height: '1px',
                    background: 'linear-gradient(to right, #c4217599 , #00a6ff99)',
                }}>
            </div>
            <MDBContainer>
                <MDBRow className="m-0 pt-5">
                    <MDBCol size='12' md='12' className="text-center pb-4">
                        <MDBRow className="m-0">
                            {
                                footerLists.map((footerList, i) => <FooterList key={i} {...footerList}></FooterList>)
                            }
                            <MDBCol size='12' md='4' className="text-md-center pb-4 mt-md-n4 px-0">
                                <MDBRow className="m-0">
                                    <MDBCol size="12" className="text-md-start pb-2">
                                        <img style={{ height: '2rem' }} src='/logo/splashsoftware-gradient.png'></img>
                                    </MDBCol>
                                    <Typography component='div' variant="body2" className="opacity-50 text-md-start">
                                        <MDBCol size="12">
                                            <MDBIcon icon='mobile-alt' className="p-1 pe-2"></MDBIcon>
                                            +971 (04) 456 1660
                                        </MDBCol>
                                        <MDBCol size="12">
                                            <MDBIcon icon='envelope' className="p-1"></MDBIcon>
                                            inquiry@spls.ae
                                        </MDBCol>
                                        <MDBCol size="12" className="-d-flex -align-items-center">
                                            <MDBIcon icon='map-marker-alt' className="p-1"></MDBIcon>
                                            <span>
                                                No A502, Opus Tower,
                                                Dubai - UAE
                                            </span>
                                        </MDBCol>
                                    </Typography>
                                    {/* 
                            <FooterList
                                list={splashSoftware}
                                size='12'>
                            </FooterList> */}
                                </MDBRow>
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>




                    <MDBCol size='12 py-3'>
                        <div
                            style={{
                                display: 'block',
                                height: '1px',
                                background: '#222',
                            }}>
                        </div>

                        <div
                            style={{
                                display: 'block',
                                height: '1px',
                                background: '#222',
                            }}>
                        </div>
                    </MDBCol>
                    <MDBCol size='12 pb-3 text-center'>
                        <Typography variant="body2" className="opacity-50">
                            Copyright © 2022 Splash Software LLC.
                        </Typography>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </div>
    );
}

const FooterList = (props) => {
    const { title, list, size } = props;

    return <MDBCol size='12' md={size || '4'} className='mb-4 text-center text-md-start'>
        <Typography component='div' sx={{ typography: ['h6', 'h6'], color: '#eee' }} >{title}</Typography>
        {
            list.map((item, i) => <FooterItem key={i} {...item}></FooterItem>)
        }
    </MDBCol>
}

const FooterItem = (props) => {
    const { title, url } = props;
    return <Link
        to={`${url}`}
        className='footerLink d-block'
        style={{
            color: '#aaa',
        }}
        onMouseEnter={e => {
            $(e.currentTarget).stop().animate({
                paddingLeft: '5px',
            }, 'fast').css({
                "transition": "color 0.4s",
                color: '#00a6ff'
            })
        }}
        onMouseLeave={e => {
            $(e.currentTarget).stop().animate({
                paddingLeft: '0px',
            }, 'fast').css({
                "transition": "color 0.4s",
                color: '#aaa'
            })
        }}

    >
        <Typography component='div' variant="body2" className="">{title}</Typography>
    </Link>
}

export default Footer;