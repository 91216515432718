import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import { Drawer } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import { blockchainServices, cloudServices, dataServices, mobileDevelopment, splashSoftware, webDevelopment } from '../helpers/menuData';
// const pages = ['Services', 'About Us', 'Contact Us', 'Careers'];

const LayoutDrawer = (props) => {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    // const [anchorElUser, setAnchorElUser] = React.useState(null);

    // const handleOpenNavMenu = (event) => {
    //     setAnchorElNav(event.currentTarget);
    // };
    // const handleOpenUserMenu = (event) => {
    //     setAnchorElUser(event.currentTarget);
    // };

    // const handleCloseNavMenu = () => {
    //     setAnchorElNav(null);
    // };

    // const handleCloseUserMenu = () => {
    //     setAnchorElUser(null);
    // };

    const [openDrawer, setOpenDrawer] = React.useState(false)

    return (
        <>
            <AppBar position="fixed"
                sx={{
                    background: 'linear-gradient(to right, #c4217599 , #00a6ff99)',
                    paddingBottom: '1px'
                }}>
                <Container className='p-0' sx={{
                    maxWidth: '100% !important', backgroundColor: '#000',
                }}>
                    <Toolbar disableGutters >
                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}
                        >
                            <Link to='/'>
                                <img alt='Splash Software Logo 1' className='ps-3' style={{ height: '2rem' }} src='/logo/splashsoftware-gradient.png'></img>
                            </Link>
                        </Typography>

                        <Typography
                            variant="h6"
                            noWrap
                            component="div"
                            sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}
                        >
                            <Link to='/'>
                                <img alt='Splash Software Logo 2' className='ps-2 ms-1' style={{ height: '1.3rem' }} src='/logo/splashsoftware-gradient.png'></img>
                            </Link>
                        </Typography>
                        <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'flex', md: 'none', justifyContent: 'end' }
                        }}>
                            <Typography variant='subtitle1'>
                                <Link className='text-pink font-weight-bold' to='/Splash/Hiring'>We Are Hiring</Link>
                            </Typography>
                        </Box>
                        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={() => setOpenDrawer(true)}
                                color="inherit">
                                <MenuIcon />
                            </IconButton>
                        </Box>

                        <Box sx={{
                            flexGrow: 1,
                            display: { xs: 'none', md: 'flex' }
                        }}>
                        </Box>
                        <Box sx={{
                            paddingRight: 1,
                            flexGrow: 0,
                            display: { xs: 'none', md: 'flex' }
                        }}>
                            {splashSoftware.map((item, i) => {
                                const { title, url } = item;
                                return <Link
                                    to={url}
                                    key={i}>
                                    <Typography variant='body1' className={`text-${title.toLowerCase().indexOf('hiring') > 0 ? 'pink text-shadow-pink' : 'white'} px-2 `}>{title}</Typography>
                                </Link>
                            })}
                        </Box>

                        <Drawer
                            anchor={'left'}
                            open={openDrawer}
                            PaperProps={{
                                style: {
                                    boxShadow: '0 0 1px #00a6ff99',
                                    background: '#000'
                                }
                            }}
                            onClose={() => setOpenDrawer(false)}>
                            <Box sx={{ width: 300, minHeight: '100vh', backgroundColor: '#000' }} role="presentation">
                                <List>
                                    {splashSoftware.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer}  {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                                <List>
                                    <ListItem><Typography className='text-gradient'>Blockchain Services</Typography></ListItem>
                                    {blockchainServices.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer}  {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                                <List>
                                    <ListItem><Typography className='text-gradient'>Cloud Services</Typography></ListItem>
                                    {cloudServices.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer}  {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                                <List>
                                    <ListItem><Typography className='text-gradient'>Data Services</Typography></ListItem>
                                    {dataServices.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer}  {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                                <List>
                                    <ListItem><Typography className='text-gradient'>Web Development</Typography></ListItem>
                                    {webDevelopment.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer}  {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                                <List>
                                    <ListItem><Typography className='text-gradient'>Mobile Development</Typography></ListItem>
                                    {mobileDevelopment.map((item, i) => { return <StyledMobileListItem setOpenDrawer={setOpenDrawer} {...item} key={i}></StyledMobileListItem> })}
                                </List>
                                <StyledDivider></StyledDivider>
                            </Box>
                        </Drawer>

                    </Toolbar>
                </Container>
            </AppBar>
            <Toolbar />
            <Box>
                <Box sx={{ minHeight: '65vh' }}>
                    {props.children}
                </Box>
                <Footer></Footer>
            </Box>
        </>
    );
}

const StyledDivider = () => {
    return <div style={{
        display: 'block',
        height: '1px',
        background: 'linear-gradient(to right, #c4217599 , #00a6ff99)',
    }}></div>
}

const StyledMobileListItem = (props) => {
    const { title, url, setOpenDrawer } = props;
    return <ListItem
        className='py-1'
        onClick={() => setOpenDrawer(false)}
        onKeyDown={() => setOpenDrawer(false)}>
        <Link to={url}>
            <Typography variant='subtitle1' className={`text-${title.toLowerCase().indexOf('hiring') > 0 ? 'pink text-shadow-pink' : 'white'} `}>
                {title}
            </Typography>
        </Link>
    </ListItem>
}

export default LayoutDrawer
