import React from 'react'
import Typography from '@mui/material/Typography';

const DataAnalysis = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Error creeps into data every step along the way as it makes it way to the business and analytics team for usage and insights. Companies are seeing an increase in data volume, data complexity, number of data sources and number of platforms (Lake, Cloud, Cloud Warehouses, Hadoop).
                </p>


                <h5>Collect Big Data</h5>
                <p>
                    Gathering different types of data from different sources, integrating all together and making them ready for processing.
                </p>
                <h5>Big Data Processing</h5>
                <p>
                    Big data processing is a set of techniques or programming models to access large-scale data to extract useful information for supporting and providing decisions.
                </p>
                <h5>Big Data Managing</h5>
                <p>
                    Identify frameworks for data architecture, data storage, and data operations; assess the data life cycle; and explore the practical uses of three important categories of data: metadata, reference data, and master data.
                </p>
                <h5>Data Governance, Quality, and Security</h5>
                <p>
                    Assess how data governance ensures that data stays reliable and secure; develop an effective approach to data security; and understand the best practices to comply with privacy regulations, such as GDPR, HIPAA and CCPA.
                </p>
                <h5>Data Processes and Technology</h5>
                <p>
                    Organize data technologies into four categories: data generation and acquisition; data integration and management; data analysis; and data operationalization. Identify the technologies and applications for each step of your data process.
                </p>
                <h5>Data Organization and Culture</h5>
                <p>
                    Gain a high-level view of how to build a culture to enable data-driven decision making in your organization; formally structure your organization to create value from your data; and evaluate the importance of roles such as chief data officer.
                </p>
                <h5>Data at the Leading Edge</h5>
                <p>
                    Look to the future of data, discovering what’s possible in data science, AI, and different analytic approaches that can inform your business decisions and automate processes in ways never before possible, including descriptive and prescriptive modeling.                </p>
            </Typography>
        </>
    )
}

export default DataAnalysis