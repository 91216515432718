import { Paper, Typography } from '@mui/material'
import { MDBCol, MDBContainer, MDBRow } from 'mdb-react-ui-kit'
import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";

const ServiceList = (props) => {
    const { category } = props.match.params;
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return (
        <>
            <MDBContainer fluid>
                <MDBRow className='m-0 pb-4'>
                    <MDBCol size='12' className='px-0 px-md-2'>
                        <Typography className='text-center py-5 text-white' variant='h4'>{category}</Typography>
                        <Paper
                            className='py-4 px-3'
                            sx={{
                                color: '#aaa !important',
                                backgroundColor: '#050505',
                                boxShadow: '0 0 10px #fff6',
                                '& h5': {
                                    color: '#ddd !important',
                                    paddingTop: 3
                                },
                            }}>

                        </Paper>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>

        </>
    )
}

export default ServiceList