import React from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Typography from '@mui/material/Typography';
import { MDBIcon } from 'mdb-react-ui-kit';
import { isMobile } from 'react-device-detect';

const timelineData = [
    {
        title1: 'Requirement Analysis',
        title2: 'Step 1',
        align: 'right',
        text: 'Our technical specialists research the business requirements per project and define how its business logic should work with smart contracts. Based on the collected insights, a roadmap is created.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Project Design',
        title2: 'Step 2',
        align: 'left',
        text: 'Our BAs and PMs work on data flow diagrams, while tech staff designs the technical architecture. When all the groundwork is ready, the team plans sprints, with quantitative goals per each.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Development & Release',
        title2: 'Step 3',
        align: 'left',
        text: 'The team builds and delivers the product, passing through all release stages: alpha release, beta release, release candidate, and finally production.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
    {
        title1: 'Maintenance',
        title2: 'Step 4',
        align: 'left',
        text: 'Based on the customers feedback, we gather requirements for future updates and prioritize the backlog for the most important issues.',
        icon: <MDBIcon icon='search'></MDBIcon>,
        color: 'info'
    },
]


const SmartContractDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    By bringing transparency, auditability, and speed to outdated workflows, smart contracts can change the face of many global industries. Take a look at the most prominent use cases.
                </p>
                <h5>Rapid and secure payments</h5>
                <p>
                    Get multiparty transnational payments, without fees or delays, powered by trustless blockchain-based tokenization.
                </p>
                <h5>Automated administration</h5>
                <p>
                    Provide secure, immutable, and accessible records, with transactions recorded on a blockchain.
                </p>
                <h5>Transportation management</h5>
                <p>
                    Ensure end-to-end environmental, handling and storage controls for each item and shipment.
                </p>
                <h5>Dynamic load management</h5>
                <p>
                    Manage load across fleets and trucking companies with multiparty self-executing contracts.
                </p>
                <h5>Self-managing supply chain</h5>
                <p>
                    Let multiple parties automate communication, verification, and transfer through blockchain record and smart contracts.
                </p>
                <h5>Instant dispute resolution</h5>
                <p>
                    Eliminate or instantly resolve disputes using rule-based, pre-agreed smart contracts linked to an immutable, blockchain-based record of events.
                </p>
            </Typography>
            <Timeline position={isMobile ? '' : 'alternate'}>
                {
                    timelineData.map((timelineItem, i) => <StyledTimelineItem {...timelineItem} key={i}></StyledTimelineItem>)
                }
            </Timeline>
        </>
    )
}

const StyledTimelineItem = (props) => {
    const { align, icon, title1, title2, text, color } = props;
    return <TimelineItem sx={{
        "&:before": {
            display: isMobile ? "none" : ''
        }
    }}>
        {!isMobile &&
            <TimelineOppositeContent
                sx={{ m: 'auto 0' }}
                align={align}
                variant="body2"
                color="text.secondary">
                {title2}
            </TimelineOppositeContent>
        }
        <TimelineSeparator>
            <TimelineConnector sx={{ backgroundColor: color }} />
            <TimelineDot color={color}>
                {icon}
            </TimelineDot>
            <TimelineConnector />
        </TimelineSeparator>
        <TimelineContent sx={{ py: '12px', px: 2 }}>
            <Typography variant="h6" component="span">
                {title1}
            </Typography>
            <Typography variant='body2'>
                {text}
            </Typography>
        </TimelineContent>
    </TimelineItem>
}


export default SmartContractDevelopment