import React from 'react'
import Typography from '@mui/material/Typography';

const WebDevelopment = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Being a team of web experts, Splash Software can deliver every aspect of a web project, e.g. analysis, design, development, testing, and more
                </p>
                <h5>Web App Development</h5>
                <p>
                    We provide expert-level web development services to turn your vision into a high-quality web-based solution, exactly the way you envisioned 
                </p>
                <h5>Cloud Web</h5>
                <p>
                With us, you can implement even the most challenging cloud app development projects to succeed in a data-driven world 
                </p>
                <h5>Web Optimization</h5>
                <p>
                    Our experts will perform an audit of your web-based solutions and apps, with a range of A/B tests, generating a plan of improvements to undertake 
                </p>
                <h5>Web Design</h5>
                <p>
                If you need a web design company to deliver visually engaging IT projects with outstanding UI/UX, Splash Software and our user-centered approach is your choice 
                </p>
                <h5>Web Quality Assurance</h5>
                <p>
                Take advantage of a full range of QA services to ensure the stable operation of your web apps and presences and detect bugs before going to production 
                </p>
                <h5>Web Integration</h5>
                <p>
                If your web project is data-intensive and needs to be integrated with third-party web presences or apps, Splash Software will assist you with such integrations 
                </p>

            </Typography>
        </>
    )
}

export default WebDevelopment