import React from 'react'
import Typography from '@mui/material/Typography';

const Modeling = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    We can create a world the way you want it to be! The things we can't do with photography or film, we produce in CGI and 3D. We can create artwork and visuals for your brand and have the talent to texturing and modeling a wide variety of the most divers scenes, situations and materials. We develop and produce character designs, mountain landscapes, liquids and exploded views. Nothing is too crazy for us.
                </p>
                <p>
                    Photography combined with animation or film combined with CGI and 3D? It doesn't matter to us. Our in-house creatives are experienced in combining different techniques to achieve the best results. At the beginning of the production, we always look at the possibilities. From this we can decide whether to use one technical approach, such as photography, or whether we can make a combination and produce a mixed media product.
                </p>

                <p>
                    We have a pool of creative and talented art and design team, who create appealing Graphics, UI, game assets for 2D, Isometric, and 3D mobile games. The team is skilled at creating assets and animations that are mobile-friendly and highly optimized to perform well.
                </p>
                <p>
                    we are experienced in popular mobile game development engines like Unity, Unreal, Cocos2d, Cocos-JS, and HTML5 technologies like Angular JS, Three.JS, JavaScript, etc. to build native and cross-platform games. Our team of mobile phone game developers select the right set of tools and technologies based on each game’s technic
                </p>
                <h5>Testing</h5>
                <p>
                    Our testing team’s enhanced gaming experience will help to provide Quality Assurance and compliance testing for iOS and Android games. We test the game’s performance, gameplay, device compatibility, user interface, user experience, screen orientations, resolutions, load testing, social integration, etc. Our testers also work closely with the development team to ensure the highest quality standards.
                </p>
            </Typography>
        </>
    )
}

export default Modeling