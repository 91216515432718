
const vacancies = [
    {
        jobTitle: 'Web Game Developer',
        responsibilities: [],
        requirements: [
            `Develop, troubleshoot, and debug web based games.`,
            `Manage cutting-edge technologies to improve legacy games.`,
            `Collaborate with back-end developers to integrate the game with server logic.`,
            `Gather and address technical and design requirements.`,
            `Provide training and support to internal teams.`,
            `Build reusable code and libraries for future use.`,
            `Deep typescript and OOP knowledge is a must .`,
            `Ability to communicate with designers to deliver high-quality and optimized assets.`,
            `Follow emerging technologies.`,
            `Teamwork skills with a problem-solving attitude`,
            `Excellent analytical and time management skills`
        ],
        skills: [
            'Git',
            'JWT',
            'NodeJS',
            'OOP',
            'PixiJS',
            'ReactJS',
            'Spine 2D',
            'TypeScript',
            'WebGL',
            'WebSocket'
        ],
        preferredSkills: [
            'GSAP',
            'SVG Animations',
            'OnlineGaming',
            'ThreeJS',
            'Unity',
            'ImpactJS',
            'CreateJS',
            'KonvaJS',
            'Phaser',
            'Coco',
            'Construct',
            'Gdevelop',
            'Roblox',
            'Godot',
            'Blend4web',
        ],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: 'AED 15,000 to AED 30,000 per month',//`AED10,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay',
    },
    {
        jobTitle: 'Senior Back-End Developer',
        responsibilities: [],
        requirements: [
            `Develop, troubleshoot, and debug microservices.`,
            `Manage cutting-edge technologies to improve legacy services.`,
            `Collaborate with DevOps engineers to develop microservices`,
            `Gather and address technical requirements.`,
            `Provide training and support to internal teams.`,
            `Build reusable code and libraries for future use.`,
            `Typescript and OOP experience is a must.`,
            `Follow emerging technologies.`,
            `Teamwork skills with a problem-solving attitude`,
            `Excellent analytical and time management skills`
        ],
        skills: [
            'Azure Platform',
            'Git',
            'Cloud Messaging Services',
            'Event Driven Development (EDD)',
            'Microservice Architecture',
            'MongoDB',
            'NestJS',
            'NodeJS',
            'OOP',
            'TypeScript',
            'WebSocket'
        ],
        preferredSkills: [
            'Azure Service Bus',
            'Financial Transactions',
            'PubSub',
        ],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: 'AED 15,000 to AED 30,000 per month',//`AED10,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay',
    },
    {
        jobTitle: 'Full-Stack Developer',
        responsibilities: [],
        requirements: [
            `Develop, troubleshoot, and debug applications.`,
            `Manage cutting-edge technologies to improve legacy applications.`,
            `Collaborate with Front-end developers to integrate user-facing elements with server-side logic.`,
            `Gather and address technical and design requirements.`,
            `Provide training and support to internal teams.`,
            `Build reusable code and libraries for future use.`,
            `Liaise with developers, designers, and system administrators to identify new features.`,
            `Follow emerging technologies.`,
            `Teamwork skills with a problem-solving attitude`,
            `Excellent analytical and time management skills`
        ],
        skills: [
            'CI/CD',
            'DevOps',
            'ExpressJS',
            'Git',
            'GitHub Actions',
            'HTML/CSS',
            'JavaScript/TypeScript',
            'JWT',
            'MongoDB',
            'MUI',
            'NestJS',
            'NextJS',
            'NodeJS',
            'OOP',
            'PixiJS',
            'ReactJS',
            'REST API',
            'Socket.io',
            'WebGL',

        ],
        preferredSkills: [
            'Azure',
            'GSAP',
            'Spine 2D',
            'Web Animations',
            'SVG Animations',
            'OnlineGaming',
            'ThreeJS',
            'Unity',
            'Power BI',
            'Blockchains',
            'Crypto Currency',
            'Cryptography',
        ],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: 'AED 15,000 to AED 25,000 per month',//`AED10,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay',
    },
    {
        jobTitle: 'UI/UX Designer/Graphic Artist',
        responsibilities: [
            `Planning concepts by studying relevant information and materials.`,
            `Illustrating concepts by designing examples of art arrangement, size, type size, and style and submitting them for approval.`,
            `Preparing finished art by operating necessary equipment and software.`,
            `Coordinating with outside agencies, art services, web designer, marketing, printers, and colleagues as necessary.`,
            `Contributing to team efforts by accomplishing tasks as needed.`,
            `Communicating with clients about layout and design.`,
            `Creating a wide range of graphics and layouts for product illustrations, company logos, and websites with software such as photoshop.`,
            `Reviewing final layouts and suggesting improvements when necessary.`,
            `Creating user-centered designs by understanding business requirements, and user feedback`,
            `Designing and delivering wireframes, user stories, user journeys, and mockups optimized for a wide range of devices and interfaces.`,
            `Translating requirements into style guides, design systems, design patterns, and attractive user interfaces`,
            `Designing UI elements such as input controls, navigational components, and informational components`,
            `Creating original graphic designs (e.g. images, sketches, and tables)`,
            `Identifying and troubleshooting UX problems (e.g. responsiveness)`
        ],
        requirements: [
            `Expert in responsive design, mobile, and web UI`,
            `Experience as a graphic designer or in a related field.`,
            `Demonstrable graphic design skills with a strong portfolio.`,
            `Any experience with 3D Designing software is a bonus.`,
            `A strong eye for visual composition.`,
            `Effective time management skills and the ability to meet deadlines.`,
            `Able to give and receive constructive criticism.`,
            `Understanding of marketing, production, website design, corporate identity, product packaging, advertisements, and multimedia design.`,
            `Experience with computer-aided design.`,
            `At least 2-3 years experience as Graphic Designer`,
        ],
        skills: ['Figma', 'Illustrator', 'Photoshop', 'Blender'],
        preferredSkills: ['3D Modeling', 'Game Design', 'Spine 2D', '2D/3D Animations', 'Adobe AE', 'Adobe Animate'],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: `AED7,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay'
    },

    {
        jobTitle: 'Back-End Developer',
        responsibilities: [],
        requirements: [
            `Develop, troubleshoot, and debug applications.`,
            `Manage cutting-edge technologies to improve legacy applications.`,
            `Collaborate with Front-end developers to integrate user-facing elements with server-side logic.`,
            `Gather and address technical and design requirements.`,
            `Provide training and support to internal teams.`,
            `Build reusable code and libraries for future use.`,
            `Liaise with developers, designers, and system administrators to identify new features.`,
            `Follow emerging technologies.`,
            `Teamwork skills with a problem-solving attitude`,
            `Excellent analytical and time management skills`
        ],
        skills: [
            'CI/CD',
            'DevOps',
            'ExpressJS',
            'Git',
            'GitHub Actions',
            'HTML/CSS',
            'JavaScript/TypeScript',
            'JWT',
            'MongoDB',
            'NestJS',
            'NodeJS',
            'OOP',
            'REST API',
            'Socket.io',

        ],
        preferredSkills: [
            'Azure',
            'OnlineGaming',
            'Power BI',
            'Blockchains',
            'Crypto Currency',
            'Cryptography',

        ],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: 'AED 12,000 to AED 15,000',//`AED10,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay'
    },

    {
        jobTitle: 'Front-End Developer',
        responsibilities: [],
        requirements: [
            `Expert in HTML5`,
            `Expert in CSS3 / SASS / SCSS`,
            `Expert in Bootstrap`,
            `Expert in Material Design`,
            `Expert in Javascript / jQuery / Ajax`,
            `Experience with WebGL / GSAP`,
            `Experience with Gulp / Webpack / NPM`,
            `Basic understanding of Server and Hosting`,
            `Basic understanding of Photoshop, Illustrator and Adobe XD`,
            `Basic understanding of SEO, complying with today's standards`,
            `Proficient understanding of cross-browser compatibility issues and ways to work around them`
        ],
        skills: [
            'CI/CD',
            'Git',
            'GitHub Actions',
            'HTML/CSS',
            'JavaScript/TypeScript',
            'JWT',
            'MUI',
            'NextJS',
            'NodeJS',
            'OOP',
            'PixiJS',
            'ReactJS',
            'REST API',
            'Socket.io',
            'WebGL',

        ],
        preferredSkills: [
            'Azure',
            'GSAP',
            'Spine 2D',
            'Web Animations',
            'SVG Animations',
            'OnlineGaming',
            'ThreeJS',
            'Unity',
        ],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Male/Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: 'AED 12,000 to AED 15,000 per month',//`AED10,000 – AED12,000 per month`,
        location: 'Dubai, Business Bay',
    },
    // {
    //   jobTitle: 'SEO Specialist',
    //   responsibilities: [
    //     `Performing organic keyword research.`,
    //     `On-page / off-page SEO editing and optimization`,
    //     `Preparing detailed strategy plans and reports`,
    //     `Improve and drive organic traffic to valuable landing pages and deliver an excellent experience for site users.`,
    //     `Developing link building strategies`,
    //     `Proficient in optimizing Google business listing.`,
    //     `Analyzing keywords and SEO techniques used by competitors`,
    //     `Keeping updated on both white hat and black hat SEO strategies to avoid stay within search engine guidelines`,
    //     `Optimizing content regularly on the website.`,
    //     `Improving website performance.`,
    //     `Staying up to date with the latest Google Algorithm changes.`
    //   ],
    //   requirements: [
    //     `Proficient in competitor’s analysis`,
    //     `(GA/GSC/GMB, Screaming frog, Sitebulb, MOZ, Semrush, Ahrefs & browser SEO extensions)`,
    //     `Good understanding of HTML, CSS, JS, and schema markup.`,
    //     `Solid Excel/Google Sheets and reporting skills.`,
    //     `Comfortable with technical SEO and web code best practices`,
    //     `Strong research, writing, and editorial skills, with the ability to edit and rewrite quickly`,
    //     `Deep understanding of the content side as well as technical server-side`,
    //     `Passionate about SEO and digital analytics, with the proactivity to further develop your skill set within the world of digital marketing`,
    //     `Excellent communication skills`,
    //     `Familiar with SPA website development techniques`,
    //     `Web-development/coding/design background and digital marketing experience a plus!`,
    //     `Fluent English speaker, extra language is a Plus`
    //   ],
    //   skills: ['GA', 'GSC', 'GMB'],
    //   preferredSkills: [],
    //   languages: ['English'],
    //   workingTime: '10:00 to 7:00pm with 1 day off',
    //   jobType: 'Full-time, Permanent',
    //   gender: 'Male/Female',
    //   nationalities: ['British', 'Canadian', 'American', 'Australian'],
    //   salary: `AED8,000 – AED10,000 per month`,
    //   location: 'Dubai, Business Bay'
    // },

    // {
    //   jobTitle: 'Human Resource Officer',
    //   responsibilities: [
    //     `Recruitment: sourcing + interviewing + selection + offering + contracting`,
    //     `Onboarding: visa + insurance + accesses + file creation + orientation`,
    //     `Payroll management`,
    //     `Performance management`,
    //     `Employees services`
    //   ],
    //   requirements: [
    //     `A true desire to satisfy the needs of others in a fast paced environment.`,
    //     `Refined verbal and written communication skills.`,
    //     `Must be proficient in general computer knowledge`,
    //     `Bachelor’s degree`,
    //     `Minimum 3-5 years of experience in Human Resources in a UAE based company`,
    //     `Strong understanding of UAE labour law`,
    //     `Strong communication skills to form effective working relationships with people at all levels`,
    //     `Professional yet approachable and compassionate personality, as employees should feel comfortable discussing personal issues`,
    //     `Dynamic, charismatic and enthusiastic personality with good organizational skills`,
    //     `Team leader, with an ability to influence others to achieve results and encourage professional and positive working environment`
    //   ],
    //   skills: ['MS Word', 'MS Excel', 'MS PowerPoint', 'MS Outlook', 'Microsoft 365'],
    //   preferredSkills: [],
    //   languages: ['English'],
    //   workingTime: '10:00 to 7:00pm with 1 day off',
    //   jobType: 'Full-time, Permanent',
    //   gender: 'Female',
    //   nationalities: ['British', 'Canadian', 'American', 'Australian'],
    //   salary: `AED8,000 – AED12,000 per month`,
    //   location: 'Dubai, Business Bay'
    // },
    // {
    //   jobTitle: 'Personal Assistant',
    //   responsibilities: [
    //     `Prepare daily schedules for the manager, including meetings, breaks, lunch, etc.`,
    //     `Acting as the first point of contact: dealing with correspondence and phone calls`,
    //     `Coordinate travel accommodations, including ground travel, air travel, and hotel stays. A personal assistant may be required to go along on business trips with the manager`,
    //     `Handle incoming and outgoing mail`,
    //     `Take and record meeting notes`,
    //     `To go along on a meeting if needed with clients all over UAE if required`,
    //     `Organizing events and conferences `,
    //     `Reminding the manager/executive of important tasks and deadlines`,
    //     `Typing, compiling and filing related personal documents with direct supervision from a manager, and correspondence`,
    //     `Implementing and maintaining procedures/administrative systems`,
    //     `Support staff needs`,
    //     `Collating and filing personal expenses`
    //   ],
    //   requirements: [
    //     `Excellent oral and written communication skills and can communicate well and competitively`,
    //     `Ability to work in a fast-paced environment and ready to grow`,
    //     `Excellent in the knowledge of MS Office`,
    //     `Fast learner`,
    //     `Serious and responsible`,
    //     `Self-motivated with a results-driven approach`,
    //     `Discretion and trustworthiness: you will often be a party of confidential information`,
    //     `Flexibility and adaptability`,
    //     `Organizational skills and the ability to multitask`,
    //     `The ability to be proactive and take the initiative`
    //   ],
    //   skills: ['MS Word', 'MS Excel', 'MS PowerPoint', 'MS Outlook', 'Microsoft 365'],
    //   preferredSkills: [],
    //   languages: ['English'],
    //   workingTime: '10:00 to 7:00pm with 1 day off',
    //   jobType: 'Full-time, Permanent',
    //   gender: 'Female',
    //   nationalities: ['British', 'Canadian', 'American', 'Australian'],
    //   salary: `AED8,000 – AED10,000 per month`,
    //   location: 'Dubai, Business Bay'
    // },

    // {
    //   jobTitle: 'Executive Secretary',
    //   responsibilities: [
    //     `Reporting to management and performing secretarial duties.`,
    //     `Processing, typing, editing, and formatting reports and documents.`,
    //     `Filing documents, as well as entering data and maintaining databases.`,
    //     `Liaising with internal departments and communicating with the public.`,
    //     `Directing internal and external calls, emails, and faxes to designated departments.`,
    //     `Arranging and scheduling appointments, meetings, and events.`,
    //     `Monitoring office supplies and ordering replacements.`,
    //     `Assisting with copying, scanning, faxing, emailing, note- taking, and travel bookings.`,
    //     `Preparing facilities and arranging refreshments for events, if required.`,
    //     `Observing the best business practices and etiquette.`,
    //   ],
    //   requirements: [
    //     `High School Diploma or GED.`,
    //     `Formal qualification in office administration, secretarial work, or related training.`,
    //     `1-2 years of experience as an Administrative Secretary would be advantageous.`,
    //     `Advanced proficiency in managing documents, spreadsheets, and databases.`,
    //     `Ability to liaise internally and externally on administrative matters.`,
    //     `Exceptional filing, record keeping, and organizational skills.`,
    //     `Working knowledge of printers, copiers, scanners, and fax machines.`,
    //     `Proficiency in appointment scheduling and call forwarding systems.`,
    //     `Excellent written and verbal communication skills.`,
    //     `Exceptional interpersonal skills.`
    //   ],
    //   skills: ['MS Word', 'MS Excel', 'MS PowerPoint', 'MS Outlook', 'Microsoft 365'],
    //   preferredSkills: [],
    //   languages: ['English'],
    //   workingTime: '10:00 to 7:00pm with 1 day off',
    //   jobType: 'Full-time, Permanent',
    //   gender: 'Female',
    //   nationalities: ['British', 'Canadian', 'American', 'Australian'],
    //   salary: `AED5,000 – AED7,000 per month`,
    //   location: 'Dubai, Business Bay'
    // },
    // {
    //   jobTitle: 'Accountant',
    //   responsibilities: [
    //     `Manage and ensure efficient execution of all accounts payable functions.`,
    //     `Submission of VAT returns.`,
    //     `Compile monthly payroll files for timely payment.`,
    //     `Track office licenses, leases and insurances to ensure all are renewed in a timely manner.`,
    //     `Ensure all receivables are tracked and collected in a timely manner.`,
    //     `Ownership of petty cash funds with regular reporting.`,
    //     `Regular reporting and liaising with our head office in Dubai.`,
    //     `Assist in the implementation of new accountancy software (Quickbooks).`,
    //     `Secure financial information by completing database backups, and protect operations by keeping financial information confidential.`,
    //     `Payment of company utilities and other operating expenses.`,
    //     `General HR & P.R.O. duties.`,
    //     `Monitoring and ensuring office supplies are always kept to an optimum level.`,
    //     `Executive assistant duties to the General Manager.`,
    //     `Compliance with UAE financial Laws`,
    //     `Must have experience with UAE VAT Laws, IFRS, ERP Softwares & MS Office`
    //   ],
    //   requirements: [
    //     `Bachelors Degree in Accountancy of Finance.`,
    //     `Minimum of 3 years experience in a similar role based in the UAE.`,
    //     `Strong working knowledge of the Quickbooks online system.`,
    //     `Proficient with MS Office software.`,
    //     `Sound knowledge of UAE VAT regulations.`,
    //     `Fluent in English (written & oral)`,
    //     `Meticulously orgnasied with a flexible and "can do" attitude to any tasks.`,
    //     `Pro-active mentality.`
    //   ],
    //   skills: ['MS Excel', 'MS Word', 'QuickBooks', 'Zoho'],
    //   preferredSkills: [],
    //   languages: ['English'],
    //   workingTime: '10:00 to 7:00pm with 1 day off',
    //   jobType: 'Full-time, Permanent',
    //   gender: 'Female',
    //   nationalities: ['British', 'Canadian', 'American', 'Australian'],
    //   salary: `AED8,000 – AED10,000 per month`,
    //   location: 'Dubai, Business Bay'
    // },


    {
        jobTitle: 'QA Specialist',
        responsibilities: [
            `Provide and execute test case scenario for the software developed by dev team`,
            `Documents and evaluate test results`,
            `Analyze and organize raw data`,
            `Build data systems and pipelines`,
            `Evaluate business needs and objectives`,
            `Interpret trends and patterns`,
            `Conduct complex data analysis and report on results`,
            `Prepare data for prescriptive and predictive modeling`,
            `Build algorithms and prototypes`,
            `Combine raw information from different sources`,
            `Explore ways to enhance data quality and reliability`,
            `Identify opportunities for data acquisition`,
            `Develop analytical tools and programs`,
            `Understand and provide support to end-user`
        ],
        requirements: [
            `2 years or more experience as a software QA testing experience`,
            `ERP workflow experience is a must`,
            `Hands-on experience in database design and modeling`,
            `Technical expertise with data models, data mining, and segmentation techniques`,
            `Knowledge and experience in programming languages is a huge plus`,
            `Advanced MS Excel or any spreadsheet experience`,
            `Previous data migration experience is a plus`,
            `Experience in any BI tools is a must`,
            `Good communication skills is a must`,
            `High attention to details and solid analytical skills`,
            `Degree in Computer Science, IT or any similar field.`
        ],
        skills: ['Online Game Testing', 'Blockchain', 'Crypto Currency'],
        preferredSkills: [],
        languages: ['English'],
        workingTime: '10:00 to 19:00, 1-hour break, 6-days a week',
        jobType: 'Full-time, Permanent',
        gender: 'Female',
        // nationalities: ['British', 'Canadian', 'American', 'Australian'],
        salary: `AED8,000 – AED10,000 per month`,
        location: 'Dubai, Business Bay'
    },

]

export { vacancies }