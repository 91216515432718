import React from 'react'
import Typography from '@mui/material/Typography';

const BigData = () => {
    return (
        <>
            <Typography variant='body1'>
                <p>
                    Big data is an important process of discovering hidden patterns, correlations, market trends and customer preferences for companies. Organisations can benefit from big data analytics systems and software to make decisions that can improve their business outcome.
                </p>

                <h5>Create new value from data</h5>
                <p>
                    The targets of transformation are many, but they all start the same way: with data. As enterprises migrate to cloud, they’re poised to access and use their data in new ways, and at new speeds. Tremendous value is within reach, but it will require a different way of working with data.
                </p>
                <p>
                    Unlike traditional data and analytics initiatives that lived in a technical silo, Data-led Transformation is about connecting data and people, ideas and outcomes. It requires aligning the data strategy to business goals, adapting business-wide systems to support data-driven decision making, and nurturing the talent and data culture to drive adoption on a large scale.
                </p>
                <p>
                    The result? A cloud enabled, data-driven business that’s equipped to use AI in new ways for new growth - whether that’s improving operational efficiency, delivering more exceptional customer experiences, or creating new revenue streams through intelligent products and services.
                </p>

                <h5>Value Realization</h5>
                <p>
                    Define the vision and measure value potential, aligning on business priorities and developing a data strategy that can be articulated across the organization.
                </p>
                <h5>Intelligent Data & AI Platform</h5>
                <p>
                    Build a cross-functional data platform on cloud that connects the enterprise through a secure, single source of truth. Users across the business can access, explore and use insights in the context of their individual business needs.
                </p>
                <h5>Talent and Culture</h5>
                <p>
                    Promote and incentivize a data-driven data culture through business adoption programs that engage employees and ingrain analytics across business practices and in decision making.
                </p>
            </Typography>
        </>
    )
}

export default BigData