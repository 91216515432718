import React from 'react'
import Typography from '@mui/material/Typography';
import { MDBCol, MDBContainer, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import { Box, Button, Chip, Divider, Grid, List, ListItem, ListItemIcon, ListItemText, Paper } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LinkIcon from '@mui/icons-material/Link';
import FontDownloadIcon from '@mui/icons-material/FontDownload';
const WrongWayRacer = () => {
    return (
        <>
            <MDBContainer fluid>
                <MDBRow className='m-0 pb-4'>
                    <MDBCol size='12' className='px-0 px-md-2'>
                        <Typography className='text-center py-5 text-white' variant='h4'>Wrong Way Racer!</Typography>
                        <Paper
                            className='py-4 px-3'
                            sx={{
                                color: '#aaa !important',
                                backgroundColor: '#050505',
                                boxShadow: '0 0 10px #fff6',
                                '& h5': {
                                    color: '#ddd !important',
                                    paddingTop: 3
                                },
                            }}>
                            <Box className='w-100'>
                                <Grid container>
                                    <Grid item xs={12} className='d-flex justify-content-between align-items-center'>
                                        <Typography variant='body1' className='text-white pe-2'>
                                            <Typography className='opacity-50 d-inline-block'>
                                                Difficulty:
                                            </Typography>
                                            <Box className='text-gradient d-inline ms-2'>
                                                {[...Array(5)].map((x, i) => <MDBIcon key={i} className={'cursor-pointer d-inline ' + (i < 4 ? ' ' : ' font-weight-lighter')} icon={'star'} />)}
                                            </Box>
                                            <Typography className='opacity-50'>
                                                Estimate Project Hours: 8
                                            </Typography>
                                        </Typography>
                                        <Box className=''>
                                            <Chip variant='outlined' size='small' className='px-2 m-1 text-gradient d-inline-flex' label='ReactJS' />
                                            <Chip variant='outlined' size='small' className='px-2 m-1 text-gradient d-inline-flex' label='NodeJS' />
                                            <Chip variant='outlined' size='small' className='px-2 m-1 text-gradient d-inline-flex' label='PixiJS' />
                                            <Chip variant='outlined' size='small' className='px-2 m-1 text-gradient d-inline-flex' label='Socket.io' />
                                            <Chip variant='outlined' size='small' className='px-2 m-1 text-gradient d-inline-flex' label='MUI' />
                                        </Box>
                                    </Grid>
                                </Grid>
                                <hr />
                            </Box>
                            <Grid container justifyContent='center'>
                                <Grid item xs={12}>
                                    <Typography variant='h6' className='text-white d-inline-block mb-2'>
                                        Introduction
                                    </Typography>
                                    <Typography variant='body1'>
                                        <p>
                                            Wrong Way Racer is a 2D online game. A player must position his or her car to avoid a collision with other cars driving the wrong-way.
                                        </p>
                                        <p>
                                            The web application connects to the back-end via a Socket connection to capture server messages. The server messages contain information about players, rankings, chats and the position of upcoming enemy cars (the blue cars).
                                        </p>
                                        <p>
                                            The position of the enemy cars is randomly generated by the server. The gameplay (PixiJS Rendered Canvas) should display enemies based on the message received. A player should be able properly position their car to avoid a collision. In case of a collision, an explosion animation will be displayed and the game will restart seconds later.
                                        </p>
                                    </Typography>
                                    <Typography variant='h6' className='text-white d-inline-block mt-4 mb-3'>
                                        Resources
                                    </Typography>
                                    <Box className='d-flex justify-content-start align-items-center'>
                                        <Typography variant='body1' sx={{
                                            '& a': {
                                                cursor: 'pointer',
                                                boxShadow: '0 0 5px #2df'
                                            }
                                        }}>
                                            <a target='_blank' className='me-2 rounded-pill border border-info text-info px-3 py-1 small align-items-center d-inline-flex' href='https://www.figma.com/file/4Nab0Vn7YR3znsx7Oulfv8/Assessment?node-id=3604%3A424142'>Figma UI <OpenInNewIcon fontSize='small' className='ps-1' /></a>
                                            <a target='_blank' className='mx-2 rounded-pill border border-info text-info px-3 py-1 small align-items-center d-inline-flex' href='https://cdn.spls.ae/cdn/wrong-way-assets.zip'>Assets  <CloudDownloadIcon fontSize='small' className='ps-1' /></a>
                                            <a target='_blank' className='mx-2 rounded-pill border border-info text-info px-3 py-1 small align-items-center d-inline-flex' href='wss://wrongway-racer-api.spls.ae'>Socket Connection URL (socket.io)  <LinkIcon fontSize='small' className='ps-1' /></a>
                                            <a target='_blank' className='mx-2 rounded-pill border border-info text-info px-3 py-1 small align-items-center d-inline-flex' href='https://fonts.google.com/specimen/Saira'>Font Family  <FontDownloadIcon fontSize='small' className='ps-1' /></a>
                                        </Typography>
                                    </Box>
                                    <Typography variant='h6' className='text-white d-inline-block mt-5 mb-2'>
                                        Required Technologies:
                                    </Typography>
                                    <Box className=''>
                                        <Chip variant='outlind' size='small' className='px-2 m-1 text-info d-inline-flex' label='ReactJS' />
                                        <Chip variant='outlind' size='small' className='px-2 m-1 text-info d-inline-flex' label='NodeJS' />
                                        <Chip variant='outlind' size='small' className='px-2 m-1 text-info d-inline-flex' label='PixiJS' />
                                        <Chip variant='outlind' size='small' className='px-2 m-1 text-info d-inline-flex' label='Socket.io' />
                                        <Chip variant='outlind' size='small' className='px-2 m-1 text-info d-inline-flex' label='MUI' />
                                    </Box>
                                </Grid>
                                {/* <Grid item xs={12} md={4} sx={{
                                    px: 2,
                                    '& img': {
                                        boxShadow: '0 0 15px #f186'
                                    }
                                }}>
                                    <img className='img-fluid rounded-6' src='https://cdn.spls.ae/cdn/wrong-way-racer-thumbnail.jpg' />

                                </Grid> */}
                                <Grid item xs={12}>
                                    <Typography variant='h6' className='text-white d-block mt-5 mb-2'>
                                        Expected Result:
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6} sx={{
                                    mt: 4,
                                    px: 2,
                                    '& video': {
                                        boxShadow: '0 0 15px #f186'
                                    }
                                }}>
                                    <video width='100%' className='rounded-6' controls src='https://cdn.spls.ae/cdn/expected_result.mp4'></video>
                                </Grid>
                            </Grid>
                        </Paper>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </>
    )
}

export default WrongWayRacer